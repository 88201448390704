import { Component, OnInit } from '@angular/core';
import { ClientDetails } from '../models/ClientDetails';
import { SharingAdminProviderService } from '../services/SharingAdminProviderService';
import { AuthService } from '../services/auth.service';
import { apiCallingServices } from '../services/apiCallingServices';
import { ActivatedRoute, Router } from '@angular/router';
import { delay } from 'rxjs';

declare function webengageSideMenutype(Typename:string):any;
@Component({
  selector: 'app-masterdashboard',
  templateUrl: './masterdashboard.component.html',
  styleUrls: ['./masterdashboard.component.css']
})
export class MasterdashboardComponent implements OnInit {
  
  title = 'unified-dashboard';
  ClientName:string='';
  clientBasicData:ClientDetails=new ClientDetails();
  EncodedString:any;
  parameter:any;
  newarr:any;
  mobileNo:string='';
  showleftmenu:boolean=true;
  showheader:boolean=true;

  constructor(private services:apiCallingServices,private _sharingservice: SharingAdminProviderService,private authservice:AuthService,private route:ActivatedRoute,private _router: Router) { 
    
   
  }
   ngOnInit(): void {
    console.log('ngonInit-Master');

    if (sessionStorage.getItem("user") == null) {  
     // this.parameter="";
      // this.route.queryParams.subscribe(params => {   
      //    this.EncodedString=  params['Prm'];
      //    if(this.EncodedString !=undefined && this.EncodedString!=""){
      //   this.parameter=atob(params['Prm']);
      // //this.EncodedString=  params;
      // // this.parameter=atob(this.EncodedString);
      //     this.newarr = this.parameter.split(",");               
      //     console.log(this.newarr[0]);
      //     this.GetValueFromParam();
  
      //  }
      //  });
      this._router.navigate(['login']);  
      }
    delay(10000);

    this.clientBasicData=this._sharingservice.getUserDetails();
   // this.ClientName=this.clientBasicData.ClientName;
   this.ClientName=this._sharingservice.getClientName();
      
   
  }

  GetValueFromParam()
  {
    if(this.newarr !=undefined && this.newarr[0]!="")
      {
        this.mobileNo=this.newarr[0];
       this.services.validateToken(this.mobileNo,this.newarr[1]).subscribe(data => {
         //let callflage= this.authservice.login(this.mobileNo,false);

         this.services.getClientBasicInfoData(this.mobileNo).subscribe(
          data => {
            let data1:any=data;
            this.clientBasicData.ClientName = data1[0]["client_name"];    
            this.clientBasicData.Clientcode = data1[0]["client_code"];
            this.clientBasicData.PAN = data1[0]["Pan"];
            this.clientBasicData.Email = data1[0]["Email"];
            this.clientBasicData.Mobile = data1[0]["mobile"];
          //  console.log(JSON.stringify(data1));
           // console.log('get login data:-'+ this.clientBasicData);
            if (this.clientBasicData !=null && this.clientBasicData.Mobile !='') { 
              sessionStorage.setItem("userdetail", JSON.stringify(this.clientBasicData));
              sessionStorage.setItem("user", this.mobileNo);     
              sessionStorage.setItem("leftmenuhideflag", 'true');       
              this._sharingservice.settUserDetails(this.clientBasicData);    
              this.showleftmenu=false;
              this.showheader=false;
              debugger;
              this.clientBasicData=this._sharingservice.getUserDetails();
             // this.ClientName=this.clientBasicData.ClientName;
             this.ClientName=this._sharingservice.getClientName();  
             this._router.navigate([this._router.routerState.snapshot.url]);        

             //this._router._routerState.snapshot.url
                  
              return true;
            }
            //this.errorMsg = 'Failed to login';
            //  alert('your login/password is wrong !');
              
            return false;       
          },
          error => {
            console.log("Features: Error in Exceution " + error.message);
           // this.errorMsg = 'Failed to login';
           
          }
        );


         
        }, err => {
          console.log(err);
        });
      }
      else{
        this.showleftmenu=true;
        this.showheader=true;
      }
  }

  Logout()
  {
    this.authservice.logout();
  }
  webengageSideMenu(Investmenttype:string)
  {
    webengageSideMenutype(Investmenttype);
  } 
}