import { Component, Input, OnInit } from '@angular/core';
import { apiCallingServices } from '../services/apiCallingServices';
import { SharingAdminProviderService } from '../services/SharingAdminProviderService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {

  constructor(private services:apiCallingServices,private _sharingservice: SharingAdminProviderService,private route:ActivatedRoute, public _router: Router) 
    {  }
  @Input() inputUserType: any;
  mobileNo:any;
  LIData:any=[];
  HealthData:any=[];
  MotorData:any=[];
  BikeData:any=[];
  selectedIndex: any=[];

  RequestLIFlag:boolean=false;
  CTADisplayFlag:boolean=true;
  ngOnInit(): void {
    this.mobileNo=this._sharingservice.getMobile();
    this.GetLIDashBoardData();
    this.GetMotorDashBoardData();
    this.GetHealthDashBoardData();
    this.GetBikeDashBoardData();
    //debugger;
    if(this.inputUserType=='RM')
      { 
        this.CTADisplayFlag=false;
       // this.DisapleCTA(false);
      }
    
  }
 
  DisapleCTA(flage:boolean)
  {
    var buttons = document.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
        let button = buttons[i];
       // let type = button.getAttribute('type') || 'submit'; // Submit is the default
       button.style.visibility = 'hidden';
        // ...
    }
    
  }


  GetLIDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getLIInfoData(this.mobileNo).subscribe(data => {
        this.LIData=data;
      //  debugger;
    }, err => {
      console.log(err);
    });
   
  }
  GetHealthDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getHealthInfoData(this.mobileNo).subscribe(data => {
        this.HealthData=data;
       // debugger;
    }, err => {
      console.log(err);
    });
    
  }

  GetMotorDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getMotorInfoData(this.mobileNo).subscribe(data => {
        this.MotorData=data;
       // debugger;
    }, err => {
      console.log(err);
    });
    
  }

  GetBikeDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getBikeInfoData(this.mobileNo).subscribe(data => {
        this.BikeData=data;
       // debugger;
    }, err => {
      console.log(err);
    });
    
  }



  buyhealth()
  {
    window.open('https://www.bajajcapitalinsurance.com/general-insurance/health/input/basic-details', '_blank');
  }

  buyCarInsurance()
  {
    window.open('https://www.bajajcapitalinsurance.com/general-insurance/car/lead-page', '_blank');
  }

  buyBikeInsurance()
  {
    window.open('https://www.bajajcapitalinsurance.com/general-insurance/bike/lead-page', '_blank');
  
  }
  
  sendRequestToCRM(campaign_name:string,product:string)
  {
    // debugger;
    // this.services.getCRMAPIToken().subscribe(data => {
    //   debugger;
    //   let datavalue=data;
    //   let tokenvalue=data.data.access_token;      
    //   this.CRMToken=tokenvalue;
      
    //  this.createLeadtoCRM(campaign_name,product,tokenvalue);
    // }, err => {
    //   console.log(err);
    // });

    this._sharingservice.createLeadtoCRMUsingBCL(campaign_name,product.replace(' Zero',''));
    if(product=='Life Insurance Zero')
    this.RequestLIFlag=true;
  } 


  copy(text:string){
    debugger;
    var inp =document.createElement('input');
    document.body.appendChild(inp)
    inp.value =text;
    inp.select();
    document.execCommand('copy',false);
    inp.remove();
    }

    select(index:number) {
      //this.selectedIndex.
      this.selectedIndex.push(index);
    }

   
}
