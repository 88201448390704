
declare var $: any;
/**
 * All the Utils functions will be here.
 * @author Pankaj Kumar
 * @export
 * @class Utils
 */
export default class Utils {

    private static instance: Utils;

    private constructor() {
    }

    static getInstance() {
        if (!Utils.instance) {
            Utils.instance = new Utils();
        }
        return Utils.instance;
    }

    /**
     * This function will check user credentials on the localstorage and returns the status based on that
    **/
    isUserLoggedIn(): boolean {
        /**
         * check you locastorage check etc
         */
        return false;
    }


    /**
     * save user details in the local storage
     */
    saveUserDetails(value1: any, value2: any) {
        /**
         * save data in local storage here
         */
    }


    /**
    * clear details from local storage
        */
    logOutUser() {
        /**
         * remove items from the  localstorage
         */
    }

    showAlert(msg: string, alertType: string) {
        $.notify({
            message: msg,
            align: "center",
        }, {
                type: alertType, /**danger|success */
                placement: {
                    from: 'top',
                    align: 'center'
                }
            });

            
    }

}
