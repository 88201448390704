import { Component } from '@angular/core';
import { SharingAdminProviderService } from './services/SharingAdminProviderService';
import { ClientDetails } from './models/ClientDetails';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() { }
  
  
}
