import { Injectable } from '@angular/core';
import Utils from '../helpers/Utils';
//import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { apiCallingServices } from './apiCallingServices';
import { SharingAdminProviderService } from './SharingAdminProviderService';
import { ClientDetails } from '../models/ClientDetails';
import {Location} from '@angular/common';



export class User {
  constructor(
    public mobile: string,
    public clientcode: string,
    public clientName:string,
    public PAN:string,
    public emailid:string
    ) { }
}

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class AuthService {

 location: Location;
  clientBasicData:ClientDetails=new ClientDetails();
  constructor(private http: HttpClient, private _router: Router,private _webserviceExecuter: apiCallingServices,location: Location,private _sharingservice: SharingAdminProviderService) { 
   // constructor(private http: HttpClient, private _router: Router,private _webserviceExecuter: apiCallingServices,private _sharingservice: SharingAdminProviderService) { 
    this.location=location;
  }




  isAuthenticated(): boolean {
    if (sessionStorage.getItem("userdetail") === null) {  
      return false;
    }
    else { 
      return true;
    }
  }

  logout() {
    debugger;
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userdetail");
    sessionStorage.removeItem("leftmenuhideflag");
    sessionStorage.clear();
    localStorage.clear();
    //alert('Logout Successfully');
   // Utils.getInstance().showAlert("Logout Successfully",'success');
    this._router.navigate(['login']);
    //var data = localStorage.getItem("user");    
    this._sharingservice.settUserDetails(new ClientDetails());
   //this._sharingservice.settUserDetails(null);
   
    //console.log("" + JSON.stringify(data));
  }

   login(mobile:string,redirecttoHomeFlage:boolean=true) {
    //var authenticatedUser = users.find(u => u.email === user.email);
   // console.log(authenticatedUser);
  //  debugger;
  //  console.log(mobile);
    this._webserviceExecuter.getClientBasicInfoData(mobile).subscribe(
      data => {
        debugger;        
        let data1:any=data;
        if(data==undefined)
          return;
        this.clientBasicData.ClientName = data1[0]["client_name"];    
        this.clientBasicData.Clientcode = data1[0]["client_code"];
        this.clientBasicData.PAN = data1[0]["Pan"];
        this.clientBasicData.Email = data1[0]["Email"];
        this.clientBasicData.Mobile = data1[0]["mobile"];
       // console.log(JSON.stringify(data1));
       // console.log('get login data:-'+ this.clientBasicData);
        if (this.clientBasicData !=null && this.clientBasicData.Mobile !='') { 
          sessionStorage.setItem("userdetail", JSON.stringify(this.clientBasicData));
          sessionStorage.setItem("user", mobile);          
          this._sharingservice.settUserDetails(this.clientBasicData);        
          if(redirecttoHomeFlage==true)   
            this._router.navigate(['home']);          
          return true;
        }
        //this.errorMsg = 'Failed to login';
        //  alert('your login/password is wrong !');
          Utils.getInstance().showAlert("your login/password/role is wrong ! " , "danger");   
        return false;       
      },
      error => {
        console.log("Features: Error in Exceution " + error.message);
       // this.errorMsg = 'Failed to login';
       Utils.getInstance().showAlert("your login/password is wrong ! " , "danger"); 
      }
    );  
   // localStorage.setItem("user", JSON.stringify(mobile));
    //return true;
  }


}
