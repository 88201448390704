import { Component, OnInit } from '@angular/core';
import { apiCallingServices } from '../services/apiCallingServices';
import { Router } from '@angular/router';
import { ClientDetails } from '../models/ClientDetails';
import { SharingAdminProviderService } from '../services/SharingAdminProviderService';

declare function webengageSignUp(fname:string,lname:string,email:string):any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  fname: string = '';
  lname: string = '';
  email: string = '';
  mobileno:any='';
  clientBasicData:ClientDetails=new ClientDetails();
  constructor(private services:apiCallingServices,private _router: Router,private _sharingservice: SharingAdminProviderService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem("user") != null) {  
      this.mobileno=sessionStorage.getItem("user");
    }
    else
    {
      this._router.navigate(['login']);  
    }
    
  }

  signup()
  {
    //alert(this.fname);
    webengageSignUp(this.fname,this.lname,this.email);

    debugger;
    this.services.InsertSignUpValue(this.mobileno,this.fname,this.lname,this.email).subscribe(data =>
       {
      debugger;
      let data1:any=data;
      if(data==0 || data==1) return;
        this.clientBasicData.ClientName = data1[0]["client_name"];    
        this.clientBasicData.Clientcode = data1[0]["client_code"];
        this.clientBasicData.PAN = data1[0]["Pan"];
        this.clientBasicData.Email = data1[0]["Email"];
        this.clientBasicData.Mobile = data1[0]["mobile"];
        //console.log(JSON.stringify(data1));
      //  console.log('get login data:-'+ this.clientBasicData);
        if (this.clientBasicData !=null && this.clientBasicData.Mobile !='') { 
          sessionStorage.setItem("userdetail", JSON.stringify(this.clientBasicData));
          sessionStorage.setItem("user", this.mobileno);          
          this._sharingservice.settUserDetails(this.clientBasicData); 
            this._router.navigate(['home']);    
        }
      
    }, err => {
      console.log(err);
    });
  }

}
