<div class="body-container-wrapper">

    <section class="sso_login">
        <div class="login_wrapper">
            <div class="_left">
                <!-- <img src="./images/left_banner.png" class="img-fluid _bannerImg" alt="banner"> -->
                <video class="_bannerImg" muted loop autoplay>
                    <source src="./assets/images/banner_video.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div class="floating">
                    <img src="./assets/images/logo.png" class="img-fluid _logo">
                    <h1 class="headline">Creating Crorepati’s for more than 60 Years</h1>
                </div>
            </div>
            <div class="_right">
                <div class="form">
                    <div class="welcome">
                        <h2 class="_heading">Sign Up with BajajCapital</h2>
                        <div class="login">
                            <div class="input-effect">
                                <input class="effect-16" type="text" placeholder="" id="fname" name="fname" autocomplete="off" [(ngModel)]="fname" required>
                                <label>First Name</label>
                            </div>
                            <div class="input-effect">
                                <input class="effect-16" type="text" placeholder="" id="lname" name="lname"  autocomplete="off" [(ngModel)]="lname" required>
                                <label>Last Name</label>
                            </div>
                        </div>
                        <div class="login">
                            <div class="input-effect mt-3 w-100">
                                <input class="effect-16" type="text" placeholder="" id="email" name="email" autocomplete="off" [(ngModel)]="email" required >
                                <label>Enter your Email Address</label>
                            </div>
                        </div>
                        <div class="generateOtp">
                            <button class="btn" (click)="signup()">Get Started</button>
                        </div>
                        <!-- <div class="signup">
                            <span class="or">OR</span>
                            <p class="text">Signup with BajajCapital via</p>
                            <div class="links">
                                <a href="#" class="tabs">
                                    <img src="./assets/images/icons/google.png" width="32" class="img-fluid" alt="google">
                                </a>
                                <a href="#" class="tabs">
                                    <img src="./assets/images/icons/apple.png" width="32" class="img-fluid"
                                        alt="apple"></a>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
