<div class="body-container-wrapper">
    <section class="sso_login">
        <div class="login_wrapper">
            <div class="_left">
                <!-- <img src="./images/left_banner.png" class="img-fluid _bannerImg" alt="banner"> -->
                <video class="_bannerImg" muted autoplay playsinline loop oncanplay="this.play()" onloadedmetadata="this.muted = true"> 
                    <source src="./assets/images/banner_video.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div class="floating">
                    <img src="./assets/images/logo.png" class="img-fluid _logo">
                    <h1 class="headline">Creating Crorepati’s for more than 60 Years</h1>
                </div>
            </div>
            <div class="_right">
                <div *ngIf="!otpverifyscreenFlag" class="form">
                    <div class="welcome">
                        <h2 class="_heading">Welcome to BajajCapital</h2>
                        <p class="_subheading">Enter details to access one platform for all your financial needs</p>
                        <div class="input-effect">
                            <!-- <div class="country_code">
                                <select class="codes">
                                    <option value="">+91</option>
                                    <option value="">+92</option>
                                    <option value="">+93</option>
                                    <option value="">+94</option>
                                    <option value="">+95</option>
                                    <option value="">+96</option>
                                    <option value="">+97</option>
                                </select>
                            </div> -->
                            <input class="effect-16" type="text"  id="mobile" name="mobile"  [(ngModel)]="mobile" 
                            appBlockCopyPaste autocomplete="off"
                            minlength="10" maxlength="10" (input)="showGenerateOtpBtn();"
                            (keypress)="numbersOnly1($event);" (keydown)="onKeyDown($event)" on-focusout="focusOutFunction()">
                            <label>Mobile Number</label>

                            <div class="verify" *ngIf="veiwOTPBtnFlag">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect width="24" height="24" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM15.9709 8.87922C15.7952 8.73281 15.5685 8.66233 15.3407 8.68333C15.113 8.70432 14.9029 8.81508 14.757 8.99115L10.9472 13.5628L9.01009 12.2714C8.61675 12.0388 8.11025 12.1541 7.85626 12.5339C7.60226 12.9138 7.68926 13.4259 8.05443 13.7006L11.283 15.853L16.0828 10.0932C16.2292 9.91746 16.2997 9.69072 16.2787 9.46298C16.2577 9.23523 16.147 9.0252 15.9709 8.87922Z"
                                        fill="#00B190" />
                                </svg>
                            </div>
                        </div>
                        <div class="generateOtp" >
                            <button class="btn" (click)="SendOtp()" [disabled]="!generateOTPBtnFlag">Generate OTP</button>
                        </div>
                    </div>

                </div>

                <div *ngIf="otpverifyscreenFlag" class="form" >
                    <div class="welcome">
                        <h2 class="_heading">OTP Authentication</h2>
                        <p class="_subheading">OTP sent to {{mobile}} <a class="editbtn" (click)="editmobile()">Edit Mobile Number <span
                                    class="material-icons-outlined">
                        
                                </span></a></p>
    
                        <div class="otp-input-fields" >
                            <input type="number" class="otp__digit otp__field__1" Id="otp1" [(ngModel)]="otp1" max="1"  maxlength="1"   (keypress)="numbersOnly($event);"  (keyup)="onKeyDownotp($event)" autofocus>
                            <input type="number" class="otp__digit otp__field__2" Id="otp2" [(ngModel)]="otp2" max="1"   maxlength="1"  (keypress)="numbersOnly($event);" (keyup)="onKeyDownotp($event)" >
                            <input type="number" class="otp__digit otp__field__3" Id="otp3" [(ngModel)]="otp3" max="1"  maxlength="1"  (keypress)="numbersOnly($event);" (keyup)="onKeyDownotp($event)" >
                            <input type="number" class="otp__digit otp__field__4" Id="otp4" [(ngModel)]="otp4" max="1"   maxlength="1"  (keypress)="numbersOnly($event);" (keyup)="onKeyDownotp($event)" >
                        </div>
                        <div class="resend_btn">
                            <p [ngClass]="otpmessageclass" *ngIf="otpvermsg" >{{otpValidateMessage}}</p>
                            <button class="btn" *ngIf="resendflage" (click)="otpsend()">Resend</button>
                            <p class="resend_txt" *ngIf="counterflage">Resend in {{countervalue}} seconds</p>
                        </div>
                        <div class="generateOtp">
                            <button class="btn" id="btnLogin" [disabled]="enableLoginBtn"  (click)="verifyOtp()">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<!-- updated htmls  -->
<div [ngClass]="popupclass" id="popup">
    <div class="content">
        <div class="support_box">
            <button [ngClass]="popupcloseClass"  id="sClose" (click)="popupclose()"><span class="material-icons-outlined">
                    cancel
                </span></button>
            <p class="text">This mobile number is also linked with one more account.</p>
            <p class="subtext"> Click on "Yes" for our support team to cross verify the same.</p>
            <button [ngClass]="popupYesClass" id="yes" (click)="popupYesClick()">Yes</button>
        </div>

        <div  [ngClass]="popupThxClass" class="thankyou_box" id="thankyou">
            <button class="btn cross" id="tClose" (click)="popuptnxcloseClick()"><span class="material-icons-outlined">
                    cancel
                </span></button>
            <img src="./assets/images/icons/thankyou.gif" width="74" alt="gif">
            <p class="text">Thank you!</p>
            <p class="subtext">Our team will be in touch shortly to assist you</p>
        </div>
    </div>
</div>
<!-- updated htmls  -->
</div>

 <script>
    $('#yes').click(function () {
        $('#thankyou').addClass('opacity');
    })

    $('#sClose').click(function () {
        $('#popup').removeClass('opacity');
    })
    $('#tClose').click(function () {
        $('#thankyou').removeClass('opacity');
        $('#popup').removeClass('opacity');
    })
</script> 