<div class="row mob_padd">
    <div class="col-md-2 col-12 p-0">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
            aria-orientation="vertical">
            <a class="nav-link active" id="v-health-tab" data-toggle="pill"
                href="#v-health" role="tab" aria-controls="v-health"
                aria-selected="true">Health</a>
            <a class="nav-link" id="v-nps-tab" data-toggle="pill" href="#v-life"
                role="tab" aria-controls="v-life" aria-selected="false">Life</a>
            <a class="nav-link" id="v-car-tab" data-toggle="pill" href="#v-car"
                role="tab" aria-controls="v-car" aria-selected="false">Car</a>
            <a class="nav-link" id="v-bike-tab" data-toggle="pill"
                href="#v-bike" role="tab" aria-controls="v-bike"
                aria-selected="false">Bike</a>
            <!-- <a class="nav-link" id="v-pet-tab" data-toggle="pill" href="#v-pet"
                role="tab" aria-controls="v-pet" aria-selected="false">Pet</a> -->
        </div>
    </div>
    <div class="col-md-10 col-12 p-0">
        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-health" role="tabpanel"
                aria-labelledby="v-health-tab">
                <div class="tab_content_wrapper">
                    <ng-container *ngFor="let item of HealthData">
                    <div class="_top">
                        <div class="_top_data">
                            <img src='{{ "./assets/images/logos/" + item.IMAGEPATH }}' *ngIf="item.IMAGEPATH.length>0"  width="82" class="ics"
                                alt="ics">
                            <div class="ics_text">
                                <h5 class="_heading">{{item.COMPANY_NAME+" - "+item.PLAN}}
                                </h5>

                                <p class="_total"> {{item.POLICY_TYPE}} |
                                    {{item.POLICY_NO}} <a href="#"     (click)="copy(item.POLICY_NO)"                                                          
                                        class="view" *ngIf="CTADisplayFlag"><svg
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M4.66667 3.99992V1.99992C4.66667 1.82311 4.7369 1.65354 4.86193 1.52851C4.98695 1.40349 5.15652 1.33325 5.33333 1.33325H13.3333C13.5101 1.33325 13.6797 1.40349 13.8047 1.52851C13.9298 1.65354 14 1.82311 14 1.99992V11.3333C14 11.5101 13.9298 11.6796 13.8047 11.8047C13.6797 11.9297 13.5101 11.9999 13.3333 11.9999H11.3333V13.9999C11.3333 14.3679 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6671 2.49626 14.6503 2.41488 14.617C2.3335 14.5837 2.25949 14.5347 2.19711 14.4728C2.13472 14.4108 2.0852 14.3372 2.05137 14.256C2.01754 14.1749 2.00009 14.0878 2 13.9999L2.002 4.66658C2.002 4.29859 2.302 3.99992 2.67267 3.99992H4.66667ZM3.33467 5.33325L3.33333 13.3333H10V5.33325H3.33467ZM6 3.99992H11.3333V10.6666H12.6667V2.66659H6V3.99992Z"
                                                fill="#DA2128" />
                                        </svg></a></p>

                                <!-- <p class="_total mobile mt-2 pt-2">Payment
                                    Frequency:
                                    <strong>Monthly</strong>
                                </p> -->
                            </div>
                        </div>
                        <!-- <button class="btn buynow" (click)="sendRequestToCRM('bcl_dashboard_insurance_renewal','Health Insurance')">Renew Now</button> -->
                        <button class="btn buynow" (click)="buyhealth()" *ngIf="CTADisplayFlag">Buy More</button>
                    </div>
                    <div class="_bottom">
                        <div class="_bottom_data">
                            <span class="title">Sum Insured</span>
                            <span class="value">₹{{item.SA}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Gross Premium</span>
                            <span class="value">₹{{item.GROSS_PREM}}/Year</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Policy Start Date</span>
                            <span class="value">{{item.PLY_ISSUE_DT}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Expires On</span>
                            <span class="value">{{item.MATURITY_DATE}}</span>
                        </div>  
                        <!-- <div class="_bottom_data w-100 pt-3" style="display: none;">
                            <span class="frequency">Payment Frequency:
                                <strong>Monthly</strong></span>
                        </div> -->
                        <button class="btn buynow" (click)="buyhealth()" *ngIf="CTADisplayFlag">Buy More</button>
                    </div>
                </ng-container>
                <!-- empty view  -->
                <div class="empty" *ngIf="HealthData.length==0">
                    <img src="./assets/images/box.png" class="emptybox">
                    <p class="empty_text">Stay covered for medical emergencies </p>
                    <a (click)="buyhealth()" class="link" *ngIf="CTADisplayFlag">Get Health Insurance now</a>
                </div>
                <!-- empty view  -->
                </div>
            </div>
            <div class="tab-pane fade" id="v-life" role="tabpanel"
                aria-labelledby="v-life-tab">
                <div class="tab_content_wrapper" >
                    <ng-container *ngFor="let item of LIData; let i=index">
                    <div class="_top">
                        <div class="_top_data">
                            <img src='{{ "./assets/images/logos/" + item.IMAGEPATH }}' *ngIf="item.IMAGEPATH.length>0" width="82" class="ics"
                                alt="ics">
                            <div class="ics_text">
                                <h5 class="_heading">{{item.COMPANY_NAME+" - "+item.PLAN}}
                                </h5>

                                <p class="_total">{{item.POLICY_TYPE}} |
                                    {{item.POLICY_NO}} <a href="#"   (click)="copy(item.POLICY_NO)"  
                                        class="view" *ngIf="CTADisplayFlag"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M4.66667 3.99992V1.99992C4.66667 1.82311 4.7369 1.65354 4.86193 1.52851C4.98695 1.40349 5.15652 1.33325 5.33333 1.33325H13.3333C13.5101 1.33325 13.6797 1.40349 13.8047 1.52851C13.9298 1.65354 14 1.82311 14 1.99992V11.3333C14 11.5101 13.9298 11.6796 13.8047 11.8047C13.6797 11.9297 13.5101 11.9999 13.3333 11.9999H11.3333V13.9999C11.3333 14.3679 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6671 2.49626 14.6503 2.41488 14.617C2.3335 14.5837 2.25949 14.5347 2.19711 14.4728C2.13472 14.4108 2.0852 14.3372 2.05137 14.256C2.01754 14.1749 2.00009 14.0878 2 13.9999L2.002 4.66658C2.002 4.29859 2.302 3.99992 2.67267 3.99992H4.66667ZM3.33467 5.33325L3.33333 13.3333H10V5.33325H3.33467ZM6 3.99992H11.3333V10.6666H12.6667V2.66659H6V3.99992Z"
                                                fill="#DA2128" />
                                        </svg></a></p>

                                <!-- <p class="_total mobile mt-2 pt-2">Payment
                                    Frequency:
                                    <strong>Monthly</strong>
                                </p> -->
                            </div>
                        </div>
                        <button class="btn buynow" (click)="sendRequestToCRM('bcl_dashboard_insurance','Life Insurance');select(i);" *ngIf="CTADisplayFlag">Renew Now</button>
                    </div>
                    <div class="_bottom">
                        <div class="_bottom_data">
                            <span class="title">Sum Insured</span>
                            <span class="value">₹{{item.SA}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Gross Premium</span>
                            <span class="value">₹{{item.TOTAL_PREM}}/Year</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Policy Start Date</span>
                            <span class="value">{{item.PLY_ISSUE_DT}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Expires On</span>
                            <span class="value">{{item.MATURITY_DATE}}</span>
                        </div>
                        <button class="btn buynow" (click)="sendRequestToCRM('bcl_dashboard_insurance','Life Insurance');select(i);">Renew Now</button>
                        <!-- <div class="_bottom_data w-100 pt-3">
                            <span class="frequency">Payment Frequency:
                                <strong>Monthly</strong></span>
                        </div> -->
                    </div>
                    <!-- <div class="success-msg"  *ngIf="selectedIndex === undefined || selectedIndex == i"> -->
                    <div class="success-msg"  *ngIf="selectedIndex != undefined && selectedIndex.indexOf(i)>=0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <mask id="mask0_3826_1751" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                              <rect y="0.5" width="16" height="16" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_3826_1751)">
                              <path d="M7.06683 11.5668L11.7668 6.86683L10.8335 5.9335L7.06683 9.70016L5.16683 7.80016L4.2335 8.7335L7.06683 11.5668ZM8.00016 15.1668C7.07794 15.1668 6.21127 14.9918 5.40016 14.6418C4.58905 14.2918 3.8835 13.8168 3.2835 13.2168C2.6835 12.6168 2.2085 11.9113 1.8585 11.1002C1.5085 10.2891 1.3335 9.42238 1.3335 8.50016C1.3335 7.57794 1.5085 6.71127 1.8585 5.90016C2.2085 5.08905 2.6835 4.3835 3.2835 3.7835C3.8835 3.1835 4.58905 2.7085 5.40016 2.3585C6.21127 2.0085 7.07794 1.8335 8.00016 1.8335C8.92238 1.8335 9.78905 2.0085 10.6002 2.3585C11.4113 2.7085 12.1168 3.1835 12.7168 3.7835C13.3168 4.3835 13.7918 5.08905 14.1418 5.90016C14.4918 6.71127 14.6668 7.57794 14.6668 8.50016C14.6668 9.42238 14.4918 10.2891 14.1418 11.1002C13.7918 11.9113 13.3168 12.6168 12.7168 13.2168C12.1168 13.8168 11.4113 14.2918 10.6002 14.6418C9.78905 14.9918 8.92238 15.1668 8.00016 15.1668Z" fill="#458704"/>
                            </g>
                          </svg> <p>Great! Our customer representative will reach out to you shortly</p>
                    </div>
                </ng-container>
                    <!-- empty view  -->
                    <div class="empty" *ngIf="LIData.length==0">
                        <img src="./assets/images/box.png" class="emptybox">
                        <p class="empty_text">Ensure your family's future, even when you are not around</p>
                        <a href="#" class="link" (click)="sendRequestToCRM('bcl_dashboard_insurance','Life Insurance Zero')" *ngIf="CTADisplayFlag">Get Life Insurance today</a>
                    </div>
                   <div class="empty" *ngIf="RequestLIFlag">
                        <img src="./assets/images/check_circle.png" class="successbox">
                        <p class="empty_text">Great! <br>Our customer
                            representative
                            will reach out to you shortly</p>

                    </div> 
                    <!-- empty view  -->
                </div>
            </div>
            <div class="tab-pane fade" id="v-car" role="tabpanel"
                aria-labelledby="v-car-tab">
                <div class="tab_content_wrapper" >
                    <ng-container *ngFor="let item of MotorData">
                    <div class="_top">
                        <div class="_top_data">
                            <img src='{{ "./assets/images/logos/" + item.IMAGEPATH }}' *ngIf="item.IMAGEPATH.length>0" width="82" class="ics"
                                alt="ics">
                            <div class="ics_text">
                                <h5 class="_heading">{{item.COMPANY_NAME+" - "+item.PLAN}}
                                </h5>

                                <p class="_total">{{item.POLICY_TYPE}} |
                                    {{item.POLICY_NO}} <a href="#"   (click)="copy(item.POLICY_NO)"  
                                        class="view" *ngIf="CTADisplayFlag"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M4.66667 3.99992V1.99992C4.66667 1.82311 4.7369 1.65354 4.86193 1.52851C4.98695 1.40349 5.15652 1.33325 5.33333 1.33325H13.3333C13.5101 1.33325 13.6797 1.40349 13.8047 1.52851C13.9298 1.65354 14 1.82311 14 1.99992V11.3333C14 11.5101 13.9298 11.6796 13.8047 11.8047C13.6797 11.9297 13.5101 11.9999 13.3333 11.9999H11.3333V13.9999C11.3333 14.3679 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6671 2.49626 14.6503 2.41488 14.617C2.3335 14.5837 2.25949 14.5347 2.19711 14.4728C2.13472 14.4108 2.0852 14.3372 2.05137 14.256C2.01754 14.1749 2.00009 14.0878 2 13.9999L2.002 4.66658C2.002 4.29859 2.302 3.99992 2.67267 3.99992H4.66667ZM3.33467 5.33325L3.33333 13.3333H10V5.33325H3.33467ZM6 3.99992H11.3333V10.6666H12.6667V2.66659H6V3.99992Z"
                                                fill="#DA2128" />
                                        </svg></a></p>

                                <!-- <p class="_total mobile mt-2 pt-2">Payment
                                    Frequency:
                                    <strong>Monthly</strong>
                                </p> -->
                            </div>
                        </div>
                        <!-- <button class="btn buynow" (click)="sendRequestToCRM('bcl_dashboard_insurance_renewal','Car Insurance')">Renew Now</button> -->
                        <button class="btn buynow" (click)="buyCarInsurance()" *ngIf="CTADisplayFlag">Buy More</button>
                    </div>
                    <div class="_bottom">
                        <div class="_bottom_data">
                            <span class="title">Sum Insured</span>
                            <span class="value">₹{{item.SA}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Gross Premium</span>
                            <span class="value">₹{{item.GROSS_PREM}}/Year</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Policy Start Date</span>
                            <span class="value">{{item.PLY_ISSUE_DT}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Expires On</span>
                            <span class="value">{{item.MATURITY_DATE}}</span>
                        </div>
                        <button class="btn buynow" (click)="buyCarInsurance()" *ngIf="CTADisplayFlag">Buy More</button>
                        <!-- <div class="_bottom_data w-100 pt-3">
                            <span class="frequency">Payment Frequency:
                                <strong>Monthly</strong></span>
                        </div> -->
                    </div>
                </ng-container>
                <!-- empty view  -->
                <div class="empty" *ngIf="MotorData.length==0">
                    <img src="./assets/images/box.png" class="emptybox">
                    <p class="empty_text">Drive with confidence</p>
                    <a href="#" (click)="buyCarInsurance()" class="link" *ngIf="CTADisplayFlag">Get Car Insurance today</a>
                </div>
                <!-- empty view  -->
                </div>
            </div>
            <div class="tab-pane fade" id="v-bike" role="tabpanel"
                aria-labelledby="v-bike-tab">
                <div class="tab_content_wrapper" >
                    <ng-container *ngFor="let item of BikeData">
                    <div class="_top">
                        <div class="_top_data">
                            <img src='{{ "./assets/images/logos/" + item.IMAGEPATH }}' *ngIf="item.IMAGEPATH.length>0" width="82" class="ics"
                                alt="ics">
                            <div class="ics_text">
                                <h5 class="_heading">{{item.COMPANY_NAME+" - "+item.PLAN}}
                                </h5>

                                <p class="_total">{{item.POLICY_TYPE}} |
                                    {{item.POLICY_NO}} <a href="#"   (click)="copy(item.POLICY_NO)"  
                                        class="view" *ngIf="CTADisplayFlag"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M4.66667 3.99992V1.99992C4.66667 1.82311 4.7369 1.65354 4.86193 1.52851C4.98695 1.40349 5.15652 1.33325 5.33333 1.33325H13.3333C13.5101 1.33325 13.6797 1.40349 13.8047 1.52851C13.9298 1.65354 14 1.82311 14 1.99992V11.3333C14 11.5101 13.9298 11.6796 13.8047 11.8047C13.6797 11.9297 13.5101 11.9999 13.3333 11.9999H11.3333V13.9999C11.3333 14.3679 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6671 2.49626 14.6503 2.41488 14.617C2.3335 14.5837 2.25949 14.5347 2.19711 14.4728C2.13472 14.4108 2.0852 14.3372 2.05137 14.256C2.01754 14.1749 2.00009 14.0878 2 13.9999L2.002 4.66658C2.002 4.29859 2.302 3.99992 2.67267 3.99992H4.66667ZM3.33467 5.33325L3.33333 13.3333H10V5.33325H3.33467ZM6 3.99992H11.3333V10.6666H12.6667V2.66659H6V3.99992Z"
                                                fill="#DA2128" />
                                        </svg></a></p>

                                <!-- <p class="_total mobile mt-2 pt-2">Payment
                                    Frequency:
                                    <strong>Monthly</strong>
                                </p> -->
                            </div>
                        </div>
                        <!-- <button class="btn buynow" (click)="sendRequestToCRM('bcl_dashboard_insurance_renewal','Bike Insurance')">Renew Now</button> -->
                        <button class="btn buynow" (click)="buyBikeInsurance()" *ngIf="CTADisplayFlag">Buy More</button>
                    </div>
                    <div class="_bottom">
                        <div class="_bottom_data">
                            <span class="title">Sum Insured</span>
                            <span class="value">₹{{item.SA}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Gross Premium</span>
                            <span class="value">₹{{item.GROSS_PREM}}/Year</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Policy Start Date</span>
                            <span class="value">{{item.PLY_ISSUE_DT}}</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Expires On</span>
                            <span class="value">{{item.MATURITY_DATE}}</span>
                        </div>
                        <button class="btn buynow" (click)="buyBikeInsurance()" *ngIf="CTADisplayFlag">Buy More</button>
                        <!-- <div class="_bottom_data w-100 pt-3">
                            <span class="frequency">Payment Frequency:
                                <strong>Monthly</strong></span>
                        </div> -->
                    </div>

                </ng-container>
                <!-- empty view  -->
                <div class="empty" *ngIf="BikeData.length==0">
                    <img src="./assets/images/box.png" class="emptybox">
                    <p class="empty_text">Ride with peace of mind</p>
                    <a href="#" (click)="buyBikeInsurance()" class="link" *ngIf="CTADisplayFlag">Get Bike Insurance now</a>
                </div>
                <!-- empty view  -->
                </div>
            </div>
            <!-- <div class="tab-pane fade" id="v-pet" role="tabpanel"
                aria-labelledby="v-pet-tab">
                <div class="tab_content_wrapper" >
                    <ng-container *ngFor="let item of HealthData">
                    <div class="_top">
                        <div class="_top_data">
                            <img src="./assets/images/ics.png" width="82" class="ics"
                                alt="ics">
                            <div class="ics_text">
                                <h5 class="_heading">Lifetime Health India Plan
                                </h5>

                                <p class="_total">Family Floater |
                                    211200/31/2024/172148 <a href="#"
                                        class="view"><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M4.66667 3.99992V1.99992C4.66667 1.82311 4.7369 1.65354 4.86193 1.52851C4.98695 1.40349 5.15652 1.33325 5.33333 1.33325H13.3333C13.5101 1.33325 13.6797 1.40349 13.8047 1.52851C13.9298 1.65354 14 1.82311 14 1.99992V11.3333C14 11.5101 13.9298 11.6796 13.8047 11.8047C13.6797 11.9297 13.5101 11.9999 13.3333 11.9999H11.3333V13.9999C11.3333 14.3679 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6671 2.49626 14.6503 2.41488 14.617C2.3335 14.5837 2.25949 14.5347 2.19711 14.4728C2.13472 14.4108 2.0852 14.3372 2.05137 14.256C2.01754 14.1749 2.00009 14.0878 2 13.9999L2.002 4.66658C2.002 4.29859 2.302 3.99992 2.67267 3.99992H4.66667ZM3.33467 5.33325L3.33333 13.3333H10V5.33325H3.33467ZM6 3.99992H11.3333V10.6666H12.6667V2.66659H6V3.99992Z"
                                                fill="#DA2128" />
                                        </svg></a></p>

                                <p class="_total mobile mt-2 pt-2">Payment
                                    Frequency:
                                    <strong>Monthly</strong>
                                </p>
                            </div>
                        </div>
                        <button class="btn buynow">Renew Now</button>
                    </div>
                    <div class="_bottom">
                        <div class="_bottom_data">
                            <span class="title">Sum Insured</span>
                            <span class="value">₹50</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Premium Amount</span>
                            <span class="value">₹12,922/Year</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Policy Start Date</span>
                            <span class="value">17 Apr 2024</span>
                        </div>
                        <div class="_bottom_data">
                            <span class="title">Expires On</span>
                            <span class="value">24 Apr 2024</span>
                        </div>
                        <div class="_bottom_data w-100 pt-3">
                            <span class="frequency">Payment Frequency:
                                <strong>Monthly</strong></span>
                        </div>
                    </div>
                </ng-container>
                <!-- empty view  -->
                <!-- <div class="empty" *ngIf="HealthData.length==0">
                    <img src="./assets/images/box.png" class="emptybox">
                    <p class="empty_text">No Plans found</p>
                    <a href="#" class="link">Buy Now</a>
                </div> -->
                <!-- empty view  -->
                <!-- </div>
            </div>  --> 



        </div>
    </div>
</div>