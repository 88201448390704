import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  transform(number: number, args?: any): any {
    //debugger;
    if (isNaN(number)) return null; // will only work value is a number
    if (number === null) return null;
    if (number === 0) return number;
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 2);
    const isNegative = number < 0; // will also work for Negative numbers
    let key = '';

    const powers = [
        // {key: 'Q', value: Math.pow(10, 15)},
        // {key: 'T', value: Math.pow(10 12)},
        {key: ' Cr', value: Math.pow(10, 7)},
        {key: ' Lakh', value: Math.pow(10, 5)}
        //{key: 'K', value: 1000}
    ];

    for (let i = 0; i < powers.length; i++) {
        let reduced = abs / powers[i].value;
        reduced = Math.round(reduced * rounder) / rounder;
        if (reduced >= 1) {
            abs = reduced;
            key = powers[i].key;
            break;
        }
    }
  
    return (isNegative ? '-' : '') + (Number((Math.round(abs * 100) / 100).toFixed(2))-1+1)  + key;
}
}