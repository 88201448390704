import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { MasterdashboardComponent } from './masterdashboard/masterdashboard.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {path: '',component: LoginComponent},
  {path: 'login',component: LoginComponent},
  {path: 'home',component: MasterdashboardComponent,data: { preload: true } },
  // {path: 'home?Prm=*',component: MasterdashboardComponent},
//  {path: 'home?:Prm',component: MasterdashboardComponent},
  {path: 'signup',component: SignupComponent},
  {path: 'dashboard',component: DashboardComponent,data: { preload: true }  },
  {path: 'dashboard?Prm=*',component: DashboardComponent},
  { path: '**', component: PageNotFoundComponent },
 
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
