<!-- header section -->
<header id="headsection" class="header-container-wrapper"  *ngIf="showheader">
    <div class="main-header d-lg-block d-none">
        <div class="container-wrapper">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-between">
                    <div class="logo">
                        <a href="#">
                            <img src="./assets/images/logo.png" alt="Bajaj Capital Logo">
                        </a>
                    </div>
                    <div class="col-md-6">
                        <div class="main-nav mega-menu d-none">
                            <ul>
                                <li class="has-children">
                                    <a href="https://crm.bajajcapital.com/" target="_blank">CRM</a>
                                </li>
                                <li class="has-children">
                                    <a href="https://wealth.bajajcapital.com/rm/login" target="_blank">Mutual
                                        Fund</a>
                                </li>
                                <li class="has-children">
                                    <a href="https://partner.bajajcapitalinsurance.com/"
                                        target="_blank">Insurance</a>
                                </li>
                                <li class="has-children">
                                    <a href="https://wealthmaker.in/login_new.aspx" target="_blank">WealthMaker</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="dropdown">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="material-icons-outlined">
                                    person
                                </span> Hi {{ClientName}} <span class="material-icons-outlined text-dark">
                                    expand_more
                                </span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" (click)="Logout()"><span class="material-icons-outlined">
                                        logout
                                    </span>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mobile-header d-lg-none">
        <div class="container">
            <div class="row">
                <div class="col-1 pr-0">
                    <div class="mobile-header-links justify-content-center">
                        <a onclick="OpenSideBar()" class="hamburger-menu d-flex">
                            <span class="material-icons-outlined">
                                menu
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mobile-logo">
                        <a href="#" class="logo">
                            <img src="./assets/images/logo.png" alt="" width="120">
                        </a>
                    </div>
                </div>
                <div class="col-5">
                    <div class="dropdown">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="material-icons-outlined">
                                person
                            </span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" (click)="Logout()"><span class="material-icons-outlined">
                                    logout
                                </span>Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header section  -->
<main>
    <section class="dashboard">
        <div class="sidemenu" id="sidemenu" *ngIf="showleftmenu">
            <div class="tab">
                <button class="btn tablinks active" onclick="openCity(event, 'tools')"(click)="webengageSideMenu('Dashboard')"><span
                        class="material-icons-outlined" >
                        grid_view
                    </span>Dashboard</button>
                <button class="btn tablinks" onclick="openCity(event, 'marketing')"(click)="webengageSideMenu('Calculators')"><span
                        class="material-icons-outlined" >
                        calculate
                    </span>Calculators</button>
                <button class="btn tablinks" onclick="openCity(event, 'platform')"(click)="webengageSideMenu('Help Center')"><span
                        class="material-icons-outlined" >
                        help_outline
                    </span>Help Center</button>
            </div>
        </div>
        <!-- <div class="workspace"> -->
        <!-- <router-outlet></router-outlet> -->
        <div style="width:100%;height:100%;">
        <app-dashboard></app-dashboard>
         
    </div>
      <!-- </div> -->
    </section>
</main>



