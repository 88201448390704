import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { apiCallingServices } from './services/apiCallingServices';
import { HttpClientModule } from '@angular/common/http';
import { ProductFilterPipe } from './pipes/product-filter.pipe';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { SharingAdminProviderService } from './services/SharingAdminProviderService';
import { AuthService } from './services/auth.service';
//import { MasterloginComponent } from './masterlogin/masterlogin.component';
import { MasterdashboardComponent } from './masterdashboard/masterdashboard.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { HelpercomComponent } from './helpercom/helpercom.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { ConvertNumberPipe } from './pipes/convert-number.pipe';
import { InsuranceComponent } from './insurance/insurance.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProductFilterPipe,
    LoginComponent,
   // MasterloginComponent,
    MasterdashboardComponent,
    DashboardComponent,

   CalculatorComponent,
    HelpercomComponent,
    SignupComponent,
    PageNotFoundComponent,
    ShortNumberPipe,
    ConvertNumberPipe,
    InsuranceComponent
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
    
  ],
  providers: [apiCallingServices, AuthService,
    SharingAdminProviderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
