export class ClientDetails 
{
    Clientcode:string='';
    ClientName:string='';    
    PAN:string='';
    Mobile:string='';
    Email:string='';

}

