import { Component, OnInit } from '@angular/core';
import { apiCallingServices } from '../services/apiCallingServices';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { subscribeOn } from 'rxjs';
import { ClientDetails } from '../models/ClientDetails';
import { SharingAdminProviderService } from '../services/SharingAdminProviderService';
declare function webengageLogin(mob:string):any;
declare function  webengageLoginOTP():any;
declare function  webengageLoginSuc():any;

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public otpverifyscreenFlag = false;
  mobile: string = '';
  leadId: string = '';
  otp: any = '';
  otp1: string='';
  otp2: string='';
  otp3: string='';
  otp4: string='';
  otpValidateMessage:string='';
  invalidOtpStatus:boolean=false;
  dataSuccess:string='';
  otpVerify:string='';
  enableLoginBtn:boolean=true;
  veiwOTPBtnFlag:boolean=false;
  otpValue:any={};
  otpmessageclass:string='verify_txt';


  countervalue:number=20;
  resendflage:boolean=false;
  counterflage:boolean=false;
  otpvermsg:boolean=false;
  timerintervaleId:any;
  maxResendcounter:number=0;
  generateOTPBtnFlag:boolean=false;

  popupclass:string="popup";
  popupcloseClass:string="btn cross";
  popupYesClass:string="btn yes"
  popupThxClass:string="thankyou_box"

  ngOnInit(): void {

    // $(".input-effect input").val("");

    // $(".input-effect input").focusout(function () {
    //     if ($(this).val() != "") {
    //         $(this).addClass("has-content");
    //     } else {
    //         $(this).removeClass("has-content");
    //     }
    // });
  }
  constructor(private service: apiCallingServices,private router: Router,private authservice:AuthService,private _router: Router,private _sharingservice: SharingAdminProviderService) 
  {
    debugger;
    if(this.authservice.isAuthenticated())
      {
        this._router.navigate(['home']);  
      }

   }
focusOutFunction() {
    debugger
    document.getElementById('mobile')?.classList.add("has-content");
  }
  showGenerateOtpBtn() {
    webengageLoginOTP();
    // this.mobile.length === 10 ? this.showGenerateOtp2Btn = true : this.showGenerateOtp2Btn = false
     this.veiwOTPBtnFlag= this.mobile.length === 10 && this.isMobileWrong(this.mobile) ? true : false;
    this.generateOTPBtnFlag=this.veiwOTPBtnFlag;
    if(this.generateOTPBtnFlag==true)
      this.SendOtp();
  }
  isMobileWrong(mobile:string) {
    let firstDigit = mobile.substr(0, 1);
    if (firstDigit != '6' && firstDigit != '7' && firstDigit != '8' && firstDigit != '9') {
      return 0;
    }
    else if (mobile.match(/(\w)\1{9,}/)) {
      this.veiwOTPBtnFlag=false;
      this.generateOTPBtnFlag=false;
      return 0;
    }
    else {
      return 1;
    }
  }

  numbersOnly1($event: KeyboardEvent) {
    var reg = /^[0-9]+$/;
    return (reg.test($event.key));
  };
  numbersOnly($event: KeyboardEvent) {
    // if ($event.target.value.length === 0) {
    //   if ($event.key === '0' || $event.key === '1' || $event.key === '2' || $event.key === '3' || $event.key === '4' || $event.key === '5') {
    //     return false;
    //   }
    // }
    const inputValue = ($event.target as HTMLInputElement).value;
    if(inputValue.length==1){
      //return false;
      ($event.target as HTMLInputElement).value='';
    }
   debugger;
    var reg = /^[0-9]+$/;
    return (reg.test($event.key));
  };

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      const inputValue = (event.target as HTMLInputElement).value;
      if (inputValue.endsWith(' ')) {
        event.preventDefault();
      }
    }
  }

  onKeyDownotp(event:KeyboardEvent){
    // if (event.keyCode === 32) {
    //   debugger;
    //   const inputValue = (event.target as HTMLInputElement).value;
    //   if (inputValue.endsWith(' ')) {
    //     event.preventDefault();
    //   }
    // }

    let element=<HTMLElement>event.srcElement;
    if (event.code !== 'Backspace')
         element = <HTMLElement>element.nextElementSibling;

     if (event.code === 'Backspace')
         element = <HTMLElement>element.previousElementSibling;

     if(element == null ){//&& this.otp1.length==1 && this.otp2.length==1  && this.otp3.length==1  && this.otp4.length==1 ){
         this.enableLoginBtn=false;
         this.verifyOtp();
        // setTimeout(() => {
        //   this.verifyOtp();
        // }, 1000);
         return;
     }
     else{
               element.focus();
     }

  }

  SendOtp()
  {
       this.veiwOTPBtnFlag=false;
       this.generateOTPBtnFlag=false;

    // this.service.getWMClient(this.mobile).subscribe(data1 => {
    //   console.log('data', data1)
    //   let data2:any=data1;
    //   if(data1!=undefined && data2.Status=="Success" && data2.Data.ClientDetails=="EXISTING")
    //     {
          this.service.otpSend(this.mobile ).subscribe(data => {
          //  console.log('data', data)           
            this.otpValue=data;
             this.otpverifyscreenFlag=true;
             this.counterflage=true;
             this.timerintervaleId = setInterval(() => {
              this.countervalue=this.countervalue-1 ;
              if(this.countervalue==-1){
                clearInterval(this.timerintervaleId);
                this.resendflage=true;
                this.counterflage=false;
              }
            }, 1000);
            webengageLoginSuc();

          }, err => {
            console.log(err);
            this.veiwOTPBtnFlag=true;
            this.generateOTPBtnFlag=true;
          });

        // }
        // else
        // {
        //   alert("This Mobile no is not exists");
        // }
    // }, err => {
    //   console.log(err);
    // });



  }

  otpsend()
  {
    this.maxResendcounter++;
    if(this.maxResendcounter<4){
      this.countervalue=20;
      this.resendflage=false;
      this.counterflage=true;
      this.otpvermsg=false;
      //this.otpValidateMessage='';
    }
    else{
      //debugger;
      this.resendflage=false;
      this.counterflage=false;
      return;
    }
    this.service.otpSend(this.mobile ).subscribe(data => {
    //  console.log('data', data)
      debugger;
      this.otpValue=data;
     this.otpverifyscreenFlag=true;
     this.counterflage=true;
     this.timerintervaleId = setInterval(() => {
      this.countervalue=this.countervalue-1 ;
      if(this.countervalue==-1){
        clearInterval(this.timerintervaleId);
        this.resendflage=true;
        this.counterflage=false;
        if(this.maxResendcounter==3)
          {
            this.resendflage=false;
          }

      }
    }, 1000);
  }, err => {
    console.log(err);
  })
  }


  verifyOtp() {
  
    let otp=this.otp1.toString().concat(this.otp2.toString()).concat(this.otp3.toString()).concat(this.otp4.toString());
    let sendotp=this.otpValue.OTP;
    let sendtime=this.otpValue.OTPTimeStamp;

    if(sendotp==otp)
      {

        //this.router.navigate(['dashboard']);
        this.otpvermsg=true;
        this.otpValidateMessage='OTP Verified';
        this.otpmessageclass='verify_txt';
        this.service.getWMClient(this.mobile).subscribe(data1 => {
       //   console.log('data', data1)
          let data2:any=data1;
          if(data1!=undefined && data2.Status=="Success" && data2.Data.ClientDetails=="EXISTING")
            {
              this.authservice.login(this.mobile);
              webengageLogin(this.mobile);
            }
            else
              {
              if(data2.Data.ClientDetails=="DUPLICATE")
                {
                 // alert("Duplicate Mobile/Client");
                  debugger;
                  this.popupclass="popup opacity";
                //  let popele=document.getElementById('popup');
                //  $('#'+popele).addClass('opacity');
                  return;
                }
              else{
              this.service.getClientBasicInfoData(this.mobile).subscribe(
                data2 => {
                  debugger;
                  let data1:any=data2;
                  if(data2==undefined || data2.length==0){
                    sessionStorage.setItem("user", this.mobile);
                    this._router.navigate(['signup']);    
                    return;
                  }
                  let clientBasicData=new ClientDetails()
                  clientBasicData.ClientName = data1[0]["client_name"];    
                  clientBasicData.Clientcode = data1[0]["client_code"];
                  clientBasicData.PAN = data1[0]["Pan"];
                  clientBasicData.Email = data1[0]["Email"];
                  clientBasicData.Mobile = data1[0]["mobile"];
                  if (clientBasicData !=null && clientBasicData.Mobile !='') { 
                    sessionStorage.setItem("userdetail", JSON.stringify(clientBasicData));
                    sessionStorage.setItem("user", this.mobile);          
                    this._sharingservice.settUserDetails(clientBasicData);    
                    webengageLogin(this.mobile);    
                     this._router.navigate(['home']);          
                    return true;
                  }
                  //this.errorMsg = 'Failed to login';
                  //  alert('your login/password is wrong !');
                  sessionStorage.setItem("user", this.mobile);
                  this._router.navigate(['signup']);    
                  return false;       
                },
                error => {
                  sessionStorage.setItem("user", this.mobile);
                  this._router.navigate(['signup']);    
                }
              );            

            }
          }
           }, err => {
          console.log(err);
         });
      }
      else{
        this.otpvermsg=true;
        this.otpValidateMessage="OTP not Verified.";
        this.otpmessageclass='invalidotp_txt';
      }

  }

  editmobile()
  {
    this.mobile='';
    this.otpverifyscreenFlag=false;
    this.countervalue=20;
    this.resendflage=false;
    this.counterflage=false;
    this.otpvermsg=false;
    this.otp1=this.otp2=this.otp3=this.otp4='';
  }

  popupclose()
  {
    this.popupclass="popup";
  }

  popupYesClick()
  {
   
    this.popupThxClass="thankyou_box opacity"
  }

  popuptnxcloseClick()
  {
    this.popupclass="popup";
    this.popupThxClass="thankyou_box";
    //this._router.navigate(['login']);   
    this. editmobile();
  }
}
