import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertNumber'
})
export class ConvertNumberPipe implements PipeTransform {

  transform(value: any , args?: any): number {    
    return Number(value);
  }

}
