import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class apiCallingServices {
  baseUrl:any = {};
  dev: any = {
    MfDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/MfDahsboardDetails?mobileNo=",
    PMSDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/MfDahsboardDetails?mobileNo=",
    AIFDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/AIFDahsboardDetails?mobileNo=",
    SummerizeDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/GetSumrizedData?mobile=",
    ClientBasicInfoAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/UserDetails?Mobileno=",
    LIDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/LIDashboardData?mobileno=",
    HealthDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/HealthDashboardData?mobileno=",
    MotorDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/MotorDashboardData?mobileno=",
    BikeDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/BikeDashboardData?mobileno=",
    sendotpurl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/SendotpMsguat?mobile=",
    commonServiceUrl: 'https://dev-cms.bajajcapitalone.com/services/API/common-header-footer?format=json',
    WMClient:"https://portfolio.wealthmaker.in/WMBackOfficeAPI/api/WmTools/GetClientDetails",
    tokenvalidation:"https://uatonlinemf.bajajcapital.com/MfDashboard/api/index/TokenValidation",
    //NPSDashboardurl:"https://dev-api.bajajcapitalone.com/nps/api/v1/portfolio_unified_dashboard",
    NPSDashboardurl:"https://api.bajajcapitalone.com/nps/api/v1/portfolio_unified_dashboard",
    //NPSGenerateToken:"https://dev-api.bajajcapitalone.com/nps/api/v1/getUnifiedToken",
    NPSGenerateToken:"https://api.bajajcapitalone.com/nps/api/v1/getUnifiedToken",
   // NPSstartDashboardUrl:"https://dev-api.bajajcapitalone.com/nps/api/v1/createURL_unified_dashboard",
   NPSstartDashboardUrl:"https://api.bajajcapitalone.com/nps/api/v1/createURL_unified_dashboard",
    MFProductToken:"https://uatmf.bajajcapital.com/api/ExternalSystemIntegration/generateTokenForClient",
    MFProductBuyNow:"https://uatmf.bajajcapital.com/login?appRole=CLIENT&authentication=",
    SignupInsert:"https://uatonlinemf.bajajcapital.com/MfDashboard/api/index/InsertSignUp",
    RMDetailsUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/GetRMDetails?Mobileno=",
    calculatorUrl:"https://cms.bajajcapitalone.com/services/api/bajaj-fc-landing-page",
    //CRMAPITokenUrl:"https://uatcrm.bajajcapital.com/index.php?entryPoint=leads&action=generateToken",
    //CRMAPILeadCreation:"https://uatcrm.bajajcapital.com/index.php?entryPoint=leads&action=add",
    CRMAPITokenUrl:"/index.php?entryPoint=leads&action=generateToken",
    CRMAPILeadCreation:"/index.php?entryPoint=leads&action=add",
    CRMBajajAPI:"https://dev-api.bajajcapital.com/bclcomapp/api/crmLeadpushUnified",
    CMSRecomSchemeAPI:"https://stage-cms.bajajcapitalone.com/services/api/bajaj-unified-dashboard?fomat=json",
    WealthSignUpUrl:"https://wealth.bajajcapital.com/signup"
  }
  stage: any = {
    MfDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/MfDahsboardDetails?mobileNo=",
    PMSDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/MfDahsboardDetails?mobileNo=",
    AIFDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/AIFDahsboardDetails?mobileNo=",
    SummerizeDashBoardService:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/GetSumrizedData?mobile=",
    ClientBasicInfoAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/UserDetails?Mobileno=",
    LIDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/LIDashboardData?mobileno=",
    HealthDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/HealthDashboardData?mobileno=",
    MotorDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/MotorDashboardData?mobileno=",
    BikeDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/BikeDashboardData?mobileno=",
    sendotpurl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/SendotpMsg?mobile=",
    commonServiceUrl: 'https://stage-cms.bajajcapitalone.com/services/API/common-header-footer?format=json',
    WMClient:"https://portfolio.wealthmaker.in/WMBackOfficeAPI/api/WmTools/GetClientDetails",
    tokenvalidation:"https://uatonlinemf.bajajcapital.com/MfDashboard/api/index/TokenValidation",
    //NPSDashboardurl:"https://dev-api.bajajcapitalone.com/nps/api/v1/portfolio_unified_dashboard",
    NPSDashboardurl:"https://api.bajajcapitalone.com/nps/api/v1/portfolio_unified_dashboard",
    NPSGenerateToken:"https://api.bajajcapitalone.com/nps/api/v1/getUnifiedToken",
    NPSstartDashboardUrl:"https://dev-api.bajajcapitalone.com/nps/api/v1/createURL_unified_dashboard",
    MFProductToken:"https://uatmf.bajajcapital.com/api/ExternalSystemIntegration/generateTokenForClient",
    MFProductBuyNow:"https://uatmf.bajajcapital.com/login?appRole=CLIENT&authentication=",
    SignupInsert:"https://uatonlinemf.bajajcapital.com/MfDashboard/api/index/InsertSignUp",
    RMDetailsUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/GetRMDetails?Mobileno=",
    calculatorUrl:"https://cms.bajajcapitalone.com/services/api/bajaj-fc-landing-page",
   // CRMAPITokenUrl:"https://uatcrm.bajajcapital.com/index.php?entryPoint=leads&action=generateToken",
   // CRMAPILeadCreation:"https://uatcrm.bajajcapital.com/index.php?entryPoint=leads&action=add",
   CRMAPITokenUrl:"/index.php?entryPoint=leads&action=generateToken",
   CRMAPILeadCreation:"/index.php?entryPoint=leads&action=add",
   CRMBajajAPI:"https://dev-api.bajajcapital.com/bclcomapp/api/crmLeadpushUnified",
   CMSRecomSchemeAPI:"https://stage-cms.bajajcapitalone.com/services/api/bajaj-unified-dashboard?fomat=json",
   WealthSignUpUrl:"https://wealth.bajajcapital.com/signup"

  }
  prod: any = {
    MfDashBoardService: "https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/MfDahsboardDetails?mobileNo=",
    PMSDashBoardService: "https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/MfDahsboardDetails?mobileNo=",
    AIFDashBoardService: "https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/AIFDahsboardDetails?mobileNo=",
    SummerizeDashBoardService: "https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/GetSumrizedData?Mobile=",
    ClientBasicInfoAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/UserDetails?Mobileno=",
    LIDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/LIDashboardData?mobileno=",
    HealthDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/HealthDashboardData?mobileno=",
    MotorDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/MotorDashboardData?mobileno=",
    BikeDataAPIUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/index/BikeDashboardData?mobileno=",
    sendotpurl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/SendotpMsg?mobile=",
    commonServiceUrl: 'https://cms.bajajcapitalone.com/services/API/common-header-footer?format=json',
    WMClient:"https://portfolio.wealthmaker.in/WMBackOfficeAPI/api/WmTools/GetClientDetails",
    tokenvalidation:"https://uatonlinemf.bajajcapital.com/MfDashboard/api/index/TokenValidation",
    //NPSDashboardurl:"https://dev-api.bajajcapitalone.com/nps/api/v1/portfolio_unified_dashboard",
    NPSDashboardurl:"https://api.bajajcapitalone.com/nps/api/v1/portfolio_unified_dashboard",
    NPSGenerateToken:"https://api.bajajcapitalone.com/nps/api/v1/getUnifiedToken",
    NPSstartDashboardUrl:"https://dev-api.bajajcapitalone.com/nps/api/v1/createURL_unified_dashboard",
    MFProductToken:"https://wealth.bajajcapital.com/api/ExternalSystemIntegration/generateTokenForClient",
    MFProductBuyNow:" https://wealth.bajajcapital.com/login?appRole=CLIENT&authentication=",
    SignupInsert:"https://uatonlinemf.bajajcapital.com/MfDashboard/api/index/InsertSignUp",
    RMDetailsUrl:"https://uatonlinemf.bajajcapital.com/MfDashboard/API/Index/GetRMDetails?Mobileno=",
    calculatorUrl:"https://cms.bajajcapitalone.com/services/api/bajaj-fc-landing-page",
    //CRMAPITokenUrl:"https://uatcrm.bajajcapital.com/index.php?entryPoint=leads&action=generateToken",
    //CRMAPILeadCreation:"https://uatcrm.bajajcapital.com/index.php?entryPoint=leads&action=add",
    CRMAPITokenUrl:"/index.php?entryPoint=leads&action=generateToken",
    CRMAPILeadCreation:"/index.php?entryPoint=leads&action=add",
    CRMBajajAPI:"https://prod-api.bajajcapital.com/bclcomapp/api/crmLeadpushUnified",
    CMSRecomSchemeAPI:"https://cms.bajajcapitalone.com/services/api/bajaj-unified-dashboard?fomat=json",
    WealthSignUpUrl:"https://wealth.bajajcapital.com/signup"
  }
  token:string="VW5pZmllZE1GLEJhamFqQDEyMzQ1";

  NPSUserId:string="bcl_dashboard";
  NPSPassword:string="0sPw#)eGp";
  NPSToken:string="";

  constructor(private http: HttpClient) {
    //let env = environment.envName;
    //debugger;
     let env = 'prod';
    if (env.toLowerCase() === 'dev') {
      this.baseUrl = this.dev;
    }
    else if (env.toLowerCase() === 'stage') {
      this.baseUrl = this.stage;
    }
    else if (env.toLowerCase() === 'prod') {
      this.baseUrl = this.prod;      
    }
    else
      this.baseUrl = this.dev;
  }

  //let httpOptions = new Headers({ 'Accept': 'application/json', 'Content-Type': 'application/json; charset=utf-8' });

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'Accept': 'application/json',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
        'Access-Control-Allow-Origin': '*',
        'Authorization':this.token
    })
 };

// httpOptions.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
// httpOptions.append('Access-Control-Allow-Origin', '*');   




  callingTokenBaseApi(url:any,obj:any, token:any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', token);
    if (token) {
      return this.http.post(url, obj, { headers: headers })
    }
    else
      return this.http.post(url, obj, { observe: 'response' }
      )
  }

  getMfDashBoardData(mobileNo:string) {       
    return this.http.get<any>(this.baseUrl["MfDashBoardService"] + mobileNo, this.httpOptions)
  }
  getPMEDashBoardData(mobileNo:string) {     
    return this.http.get<any>(this.baseUrl["PMSDashBoardService"] + mobileNo, this.httpOptions)
  }

  getAIFDashBoardData(mobileNo:string) {     
    return this.http.get<any>(this.baseUrl["AIFDashBoardService"] + mobileNo, this.httpOptions)
  }

  getSummerizeDashBoardData(mobileNo:string) {  
    debugger;    
    return this.http.get<any>(this.baseUrl["SummerizeDashBoardService"] + mobileNo, this.httpOptions)
  }
  getClientBasicInfoData(mobileNo:string) {     
     return this.http.get<any>(this.baseUrl["ClientBasicInfoAPIUrl"] + mobileNo, this.httpOptions)
  }

  getLIInfoData(mobileNo:string) {     
    return this.http.get<any>(this.baseUrl["LIDataAPIUrl"] + mobileNo, this.httpOptions)
 }

 getMotorInfoData(mobileNo:string) {     
    return this.http.get<any>(this.baseUrl["MotorDataAPIUrl"] + mobileNo, this.httpOptions)
 }

 getBikeInfoData(mobileNo:string) {     
  return this.http.get<any>(this.baseUrl["BikeDataAPIUrl"] + mobileNo, this.httpOptions)
}

 getHealthInfoData(mobileNo:string) {     
    return this.http.get<any>(this.baseUrl["HealthDataAPIUrl"] + mobileNo, this.httpOptions)
 }

 otpSend(mobileNo:string) {     
    return this.http.get<any>(this.baseUrl["sendotpurl"] + mobileNo, this.httpOptions)
 }

 getCommonSection() {
    //header menu,hamburgur menu, footer munu links
   // let data = this.http.get(this.baseUrl["commonServiceUrl"], this.httpOptions);
   // return data;
   return this.http.get<any>(this.baseUrl["commonServiceUrl"] , this.httpOptions)
  }

  getWMClient(mobile:string) {
  return this.http.post(this.baseUrl["WMClient"], { "MobileNo":mobile });
  }

  validateToken(mobile:string,tokenp:string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', this.token);
    return this.http.post(this.baseUrl["tokenvalidation"], { "MobileNo":mobile,"EncodedValue":tokenp },{headers:headers});
    }

  getNPSToken() {
    return this.http.post(this.baseUrl["NPSGenerateToken"], { "UserId": this.NPSUserId, "Password": this.NPSPassword });
  }

   
    GetNPSData(mobile:string,npstoken:string)
    {
      // if(this.NPSToken=="")
      //   {
      //     this.getNPSToken().subscribe(data => {
      //       let datavalue:any=data;
      //         if(datavalue.success=="true"){
      //           this.NPSToken=datavalue.token;
      //           let headers: HttpHeaders = new HttpHeaders();
      //          headers = headers.append('Authorization', this.NPSToken);
      //          return this.http.post(this.baseUrl["NPSDashboardurl"], { "MobileNo":mobile },{headers:headers});
      //         }
      //         else
      //         {
      //           let headers: HttpHeaders = new HttpHeaders();
      //           headers = headers.append('Authorization', this.NPSToken);
      //           return this.http.post(this.baseUrl["NPSDashboardurl"], { "MobileNo":mobile },{headers:headers});
      //         }
              
      //     });

      //   }
      //   else{
          let headers: HttpHeaders = new HttpHeaders();
          headers = headers.append('Authorization', npstoken);
          return this.http.post(this.baseUrl["NPSDashboardurl"], { "Mobile":mobile },{headers:headers});
      //  }
    }   

    
    getMFProductToken(mobile:string)
    {
      let httpOptionsval = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'Accept': 'application/json',
                       'api-key':'Gdvb2QgZXhhbXBsZSBmbyBob',
            'source':'external-system',
            'version': '1.0.0.0'
        }) };
      // let headers: HttpHeaders = new HttpHeaders();
      // headers = headers.append('api-key', "Gdvb2QgZXhhbXBsZSBmbyBob");
      // headers = headers.append('source', "external-system");
      // headers = headers.append('version', "1.0.0.0");  
      return this.http.post(this.baseUrl["MFProductToken"], {"contactNumber":mobile},httpOptionsval );
    }

    StartNPSUrl(pran:string,pan:string,dob:string,token:string)
    {
      let httpOptionsval = {
        headers: new HttpHeaders({ "Authorization": token
        }) };
      return this.http.post(this.baseUrl["NPSstartDashboardUrl"], {"PRAN": pran,"PAN": pan,"DOB": dob},httpOptionsval );
    }
    
    getMFBuyNowUrl(token:string)
    {
      return this.baseUrl["MFProductBuyNow"]+token;
    }

    
    InsertSignUpValue(mobile:string,fName:string,lName:string,emailId:string)
    {
      debugger;
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Authorization', this.token);
      return this.http.post(this.baseUrl["SignupInsert"], {"Mobile":mobile,"FName":fName,"LName":lName,"EmailId":emailId },{headers:headers});
      
    }

    GetRMDetails(mobile:string) {
      return this.http.get<any>(this.baseUrl["RMDetailsUrl"] + mobile, this.httpOptions)      
      }

      getCalculatorData() {     
        return this.http.get<any>(this.baseUrl["calculatorUrl"] )
     }

     getCRMAPIToken()
     {
      let username:string='apiuser';
      let password:string='Apiuser@321!';
      //let pwd =new Buffer.from(username + ":" + password).toString("base64")
      let httpOptionscrm1 = {
        headers: new HttpHeaders({
         // 'Content-Type':  'application/json',
          //"source":"external-system",
          'Authorization': 'Basic YXBpdXNlcjpBcGl1c2VyQDMyMSE=',
          'Access-Control-Allow-Origin':'*',
          //'Authorization': 'Basic ' + btoa(username+':'+password)
         // 'Authorization':"Basic " +pwd 
        })};

        return this.http.get<any>(this.baseUrl["CRMAPITokenUrl"] , httpOptionscrm1)       

     }

     CreateLeadINCRM(jsondata:any,crmtoken:string)
     {
        let httpOptionscrm = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + crmtoken
        })};

        return this.http.post<any>(this.baseUrl["CRMAPILeadCreation"],JSON.stringify(jsondata) , httpOptionscrm)       

     }


     CreateLeadINCRMusingBCLAPI(jsondata:any)
     {
        let httpOptionscrm = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'          
        })};
        return this.http.post<any>(this.baseUrl["CRMBajajAPI"],JSON.stringify(jsondata) , httpOptionscrm)   

     }

     getRecommendedScheme()
     {
      return this.http.get<any>(this.baseUrl["CMSRecomSchemeAPI"] )       
     }

     
}