<!-- <div class="workspace"> -->
<div [ngClass]="workspacecss">
    <div id="tools" class="tabcontent" style="display: block;">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="investment-tab" data-toggle="pill" href="#investment"
                    role="tab" aria-controls="investment" aria-selected="true" (click)="webengagePortfolio('Investment')"><span
                        class="material-icons-outlined">
                        auto_graph
                    </span> Investment</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="insurance-tab"  data-toggle="pill" href="#insurance" role="tab"
                    aria-controls="insurance" aria-selected="false" (click)="webengagePortfolio('Insurance')"><span class="material-icons-outlined">
                        verified_user
                    </span> Insurance</a>
            </li>
        </ul>
        <div class="tab_wrapper">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="investment" role="tabpanel"
                    aria-labelledby="investment-tab">
                    <div class="left_wrapper">
                        <div class="welcome_card">
                            <span class="beta">BETA</span>
                            <p class="text">Welcome,</p>
                            <h2 class="username">{{ClientName}}</h2>
                            <p class="sub_text">{{welcomeNotes}}</p>
                        </div>
                        <div class="invest_card element" *ngIf="!totalMFflag">
                            <h4 class="heading">Investment Portfolio

                                <div class="tooltiped">
                                    <span class="material-icons-outlined icon">
                                        info
                                    </span>
                                    <span class="tooltiptext tooltip-top">Calculation is done based on
                                        Mutual Funds, NPS, AIF and PMS investments</span>
                                </div>
                            </h4>
                            <div class="_data">
                                <div class="_current">
                                    <span class="title">Current Market Value</span>
                                    <span class="value">₹0.0</span>
                                </div>
                                <div class="_total">
                                    <span class="title">Total Invested Amount
                                         <!-- updated htmls  -->
                                         <div class="tooltiped">
                                            <span class="material-icons-outlined icon">
                                                info
                                            </span>
                                            <span class="tooltiptext tooltip-top">Cost of current
                                                holdings i.e. Cost of holdings after excluding the
                                                redemptions</span>
                                        </div>
                                        <!-- updated htmls  -->
                                    </span>
                                    <span class="value">₹0.0</span>
                                </div>
                                <div class="_returns">
                                    <span class="title">All Time Returns</span>
                                    <span class="value">₹0.0</span>
                                </div>
                                <div class="_returns w-100">
                                    <a href="#" class="link" (click)="StartJourney()" *ngIf="CTADisplayFlag">Start your Investment Journey <img
                                            src="./assets/images/right_arr.png" width="6"></a>
                                </div>
                                <img src="./assets/images/box.png" class="box" alt="box">
                            </div>
                        </div>
                        <div class="invest_card element" *ngIf="totalMFflag" >
                            <h4 class="heading">Investment Portfolio 

                                <div class="tooltiped">
                                    <span class="material-icons-outlined icon">
                                        info
                                    </span>
                                    <span class="tooltiptext tooltip-top">Calculation is done based on
                                        Mutual Funds, NPS, AIF and PMS investments</span>
                                </div>
                            </h4>
                            <div class="_data"  *ngFor="let item of summrizedTotalData">
                                <div class="_current">
                                    <span class="title">Current Market Value</span>
                                    <span class="value">₹{{((item.CMVALUE | convertNumber) + NPSCMV )| shortNumber}}</span>
                                </div>
                                <div class="_total">
                                    <span class="title">Total Invested Amount
                                         <!-- updated htmls  -->
                                         <div class="tooltiped">
                                            <span class="material-icons-outlined icon">
                                                info
                                            </span>
                                            <span class="tooltiptext tooltip-top">Cost of current
                                                holdings i.e. Cost of holdings after excluding the
                                                redemptions</span>
                                        </div>
                                        <!-- updated htmls  -->
                                    </span>
                                    <span class="value">₹{{((item.INVEST_AMT | convertNumber) + NPSInvAmount) | shortNumber}}</span>
                                </div>
                                <div class="_returns">
                                    <span class="title">All Time Returns</span>
                                    <span class="value">₹{{((item.CMVALUE | convertNumber) -(item.INVEST_AMT | convertNumber)- NPSInvAmount + NPSCMV ) | shortNumber}} <span class="percentage">{{((item.CMVALUE | convertNumber) -(item.INVEST_AMT | convertNumber)- NPSInvAmount + NPSCMV )*100/((item.INVEST_AMT | convertNumber)+ NPSInvAmount)| number:'1.0-1'}}% <svg
                                                xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                    fill="#458704" />
                                            </svg></span></span>
                                             <!-- updated htmls  -->
                                             <div class="tooltiped">
                                                <span class="material-icons-outlined icon">
                                                    info
                                                </span>
                                                <span class="tooltiptext tooltip-top">Absolute Return
                                                    Rate</span>
                                            </div>
                                            <!-- updated htmls  -->
                                </div>
                            </div>
                        </div>
                        <div class="banners mobile">
                            <div class="owl-two owl-carousel owl-theme" *ngFor="let item of BannerData">

                                <div class="item">
                                    <div class="item_data">
                                        <a class="banner_url" href='{{"https://"+item.banner_hyperlink}}' target="_blank">
                                            <!-- <span class="download">Download Now</span> -->
                                            <img [src]="item.banner_image" class="img-fluid w-100" [alt]="item.banner_img_alt_text">
                                        </a>
                                    </div>
                                </div>      
                                  
                                

                            </div>
                        </div>
                        <div class="invest_card element mob-padd">
                            <h4 class="heading">Your Investments with BajajCapital</h4>
                            <div class="row mob_padd">
                                <div class="col-md-2 col-12 p-0">
                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <a class="nav-link active" id="v-mf-tab" data-toggle="pill"
                                            href="#v-mf" role="tab" aria-controls="v-mf"
                                            aria-selected="true" (click)="webengageProductCard('Mutual Fund')" >Mutual Fund</a>
                                        <a class="nav-link" id="v-nps-tab" data-toggle="pill" href="#v-nps"
                                            role="tab" aria-controls="v-nps" aria-selected="false" (click)="webengageProductCard('NPS')">NPS</a>
                                        <a class="nav-link" id="v-aifs-tab" data-toggle="pill"
                                            href="#v-aifs" role="tab" aria-controls="v-aifs"
                                            aria-selected="false" (click)="webengageProductCard('AIFs')">AIFs</a>
                                        <a class="nav-link" id="v-pms-tab" data-toggle="pill" href="#v-pms"
                                            role="tab" aria-controls="v-pms" aria-selected="false" (click)="webengageProductCard('PMS')">PMS</a>
                                    </div>
                                </div>
                                <div class="col-md-10 col-12 p-0">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade show active" id="v-mf" role="tabpanel" 
                                            aria-labelledby="v-mf-tab">
                                            <div class="tab_content_wrapper" >
                                                <div class="_top">
                                                    <div class="_top_data">
                                                        <h5 class="_heading">Mutual Fund</h5>
                                                        <!-- <p class="_total w-100">Total no. of Schemes: 3 <a
                                                                href="#" class="view">View all</a></p> -->
                                                    </div>
                                                    <button class="btn buynow" (click)="BuyMF()" *ngIf="CTADisplayFlag">Buy Now</button>
                                                </div>
                                                <div class="_bottom" *ngFor="let item of summrizedMFData">
                                                    <div class="_bottom_data">
                                                        <span class="title">Current Amount</span>
                                                        <span class="value">₹{{item.CMVALUE | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Amount Invested</span>
                                                        <span class="value">₹{{item.INVEST_AMT | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Gain/ Loss</span>
                                                        <span class="value">₹{{(item.CMVALUE- item.INVEST_AMT) | shortNumber }} <span
                                                                class="percentage">{{(item.CMVALUE-item.INVEST_AMT)*100/item.INVEST_AMT| number:'1.0-1'}}% <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="10" height="10"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <path
                                                                        d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                        fill="#458704"></path>
                                                                </svg></span></span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">XIRR</span>
                                                        <span class="value">{{item.XXIR}}%</span>
                                                    </div>
                                                    <div class="buynowbtn">
                                                        <button class="btn" (click)="BuyMF()" *ngIf="CTADisplayFlag">Buy Now</button>
                                                    </div>
                                                </div>
                                                 <!-- empty view  -->
                                             <div class="empty"  *ngIf="EmptyMfFlag">
                                                <img src="./assets/images/box.png" class="emptybox">
                                                <p class="empty_text">Ready to turn your goals into reality? Invest in Mutual Funds.</p>
                                                <a href="#" (click)="BuyMF()" class="link" *ngIf="CTADisplayFlag">Invest Now</a>
                                            </div>
                                            <!-- empty view  -->

                                            </div>
                                            
                                        </div>
                                        <div class="tab-pane fade" id="v-nps" role="tabpanel" 
                                            aria-labelledby="v-nps-tab">
                                            <div class="tab_content_wrapper" >
                                                <div class="_top"  >
                                                    <div class="_top_data">
                                                        <h5 class="_heading">NPS</h5>
                                                        <!-- <p class="_total w-100">Total no. of Schemes: 3 <a
                                                                href="#" class="view">View all</a></p> -->
                                                    </div>
                                                    <button class="btn buynow" (click)="startNPS()" *ngIf="CTADisplayFlag">Buy Now</button>
                                                </div>
                                                <div class="_bottom">
                                                    <div class="_bottom_data">
                                                        <span class="title">Current Amount</span>
                                                        <span class="value">₹{{NPSCMV | shortNumber }}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Amount Invested</span>
                                                        <span class="value">₹{{ NPSInvAmount | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Gain/ Loss</span>
                                                        <span class="value">₹{{NPSGainPercentage | shortNumber}}
                                                             <!-- <span
                                                                class="percentage">{{NPSXIRR}} <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="10" height="10"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <path
                                                                        d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                        fill="#458704"></path>
                                                                </svg></span> -->
                                                            </span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Absolute Return</span>
                                                        <span class="value">{{NPSXIRR}}</span>
                                                    </div>
                                                    <div class="buynowbtn">
                                                        <button class="btn" (click)="startNPS()" *ngIf="CTADisplayFlag">Buy Now</button>
                                                    </div>
                                                </div>
                                                <!-- updated  htmls -->
                                                            <!-- <p class="note"><strong>Note: </strong>NPS values appear for
                                                                investments made via the Online NPS BajajCapital
                                                                Platform.</p> -->
                                                            <!-- updated  htmls -->
                                                <!-- empty view  -->
                                                <div class="empty"  *ngIf="EmptyNPSFlag"> 
                                                    <img src="./assets/images/box.png" class="emptybox">
                                                    <p class="empty_text">Secure your retirement with NPS. Begin your investment today!</p>
                                                    <a href="#" class="link" (click)="startNPS()" *ngIf="CTADisplayFlag">Invest Now</a>
                                                </div>
                                                <!-- empty view  -->
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="v-aifs" role="tabpanel" 
                                            aria-labelledby="v-aifs-tab">
                                            <div class="tab_content_wrapper">
                                                <div class="_top">
                                                    <div class="_top_data">
                                                        <h5 class="_heading">AIF</h5>
                                                    </div>
                                                    <button class="btn buynow">Interested</button>
                                                </div>
                                                <div class="_bottom"  *ngFor="let item of summrizedAIFData">
                                                    <div class="_bottom_data">
                                                        <span class="title">Current Amount</span>
                                                        <span class="value">₹{{item.CMVALUE  | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Amount Invested</span>
                                                        <span class="value">₹{{item.INVEST_AMT | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Gain/ Loss</span>
                                                        <span class="value">₹{{(item.CMVALUE- item.INVEST_AMT) | shortNumber }} <span
                                                                class="percentage">{{(item.CMVALUE-item.INVEST_AMT)*100/item.INVEST_AMT| number:'1.0-1'}}% <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="10" height="10"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <path
                                                                        d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                        fill="#458704"></path>
                                                                </svg></span></span>
                                                    </div>
                                                    <!-- <div class="_bottom_data">
                                                        <span class="title">XIRR</span>
                                                        <span class="value">{{item.XXIR}}%</span>
                                                    </div> -->
                                                </div>
                                                <!-- empty view  -->
                                                <div class="empty"  *ngIf="EmptyAIFFlag"> 
                                                    <img src="./assets/images/box.png" class="emptybox">
                                                    <p class="empty_text">Tap into exclusive investment opportunities with AIF(Alternative Investments Funds).</p>
                                                    <a href="#" class="link" (click)="AIFInterested()" *ngIf="CTADisplayFlag">I'm Interested</a>
                                                </div>
                                                <div class="empty" *ngIf="RequestAIFFlag">
                                                    <img src="./assets/images/check_circle.png" class="successbox">
                                                    <p class="empty_text">Great! <br>Our customer
                                                        representative
                                                        will reach out to you shortly</p>

                                                </div>
                                                <!-- empty view  -->
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="v-pms" role="tabpanel" 
                                            aria-labelledby="v-pms-tab">
                                            <div class="tab_content_wrapper" >
                                                <div class="_top">
                                                    <div class="_top_data">
                                                        <h5 class="_heading">PMS</h5>
                                                    </div>
                                                    <button class="btn buynow" (click)="PMSInterested(false)" *ngIf="CTADisplayFlag">Interested</button>
                                                </div>
                                                <div class="_bottom" *ngFor="let item of summrizedPMSData">
                                                    <div class="_bottom_data">
                                                        <span class="title">Current Amount</span>
                                                        <span class="value">₹{{item.CMVALUE | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Amount Invested</span>
                                                        <span class="value">₹{{item.INVEST_AMT | shortNumber}}</span>
                                                    </div>
                                                    <div class="_bottom_data">
                                                        <span class="title">Gain/ Loss</span>
                                                        <span class="value">₹{{(item.CMVALUE- item.INVEST_AMT) | shortNumber}} <span
                                                                class="percentage">{{(item.CMVALUE-item.INVEST_AMT)*100/item.INVEST_AMT| number:'1.0-1'}}%  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="10" height="10"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <path
                                                                        d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                        fill="#458704"></path>
                                                                </svg></span></span>
                                                    </div>
                                                    <!-- <div class="_bottom_data" >
                                                        <span class="title">XIRR</span>
                                                        <span class="value">{{item.XXIR}}%</span>
                                                    </div> -->
                                                    <div class="buynowbtn">
                                                        <button class="btn" (click)="PMSInterested(false)" *ngIf="CTADisplayFlag">Interested</button>
                                                    </div>
                                                </div>
                                                <!-- empty view  -->
                                                <div class="success-msg"  *ngIf="PMSRequestFlag">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                        <mask id="mask0_3826_1751" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                                                          <rect y="0.5" width="16" height="16" fill="#D9D9D9"/>
                                                        </mask>
                                                        <g mask="url(#mask0_3826_1751)">
                                                          <path d="M7.06683 11.5668L11.7668 6.86683L10.8335 5.9335L7.06683 9.70016L5.16683 7.80016L4.2335 8.7335L7.06683 11.5668ZM8.00016 15.1668C7.07794 15.1668 6.21127 14.9918 5.40016 14.6418C4.58905 14.2918 3.8835 13.8168 3.2835 13.2168C2.6835 12.6168 2.2085 11.9113 1.8585 11.1002C1.5085 10.2891 1.3335 9.42238 1.3335 8.50016C1.3335 7.57794 1.5085 6.71127 1.8585 5.90016C2.2085 5.08905 2.6835 4.3835 3.2835 3.7835C3.8835 3.1835 4.58905 2.7085 5.40016 2.3585C6.21127 2.0085 7.07794 1.8335 8.00016 1.8335C8.92238 1.8335 9.78905 2.0085 10.6002 2.3585C11.4113 2.7085 12.1168 3.1835 12.7168 3.7835C13.3168 4.3835 13.7918 5.08905 14.1418 5.90016C14.4918 6.71127 14.6668 7.57794 14.6668 8.50016C14.6668 9.42238 14.4918 10.2891 14.1418 11.1002C13.7918 11.9113 13.3168 12.6168 12.7168 13.2168C12.1168 13.8168 11.4113 14.2918 10.6002 14.6418C9.78905 14.9918 8.92238 15.1668 8.00016 15.1668Z" fill="#458704"/>
                                                        </g>
                                                      </svg> <p>Great! Our customer representative will reach out to you shortly</p>
                                                </div>
                                                <div class="empty"  *ngIf="EmptyPMSFlag"> 
                                                    <img src="./assets/images/box.png" class="emptybox">
                                                    <p class="empty_text">Enhance your wealth management with our PMS(Portfolio Management Services) solutions.</p>
                                                    <a href="#" class="link" (click)="PMSInterested(true)" *ngIf="CTADisplayFlag">I'm Interested</a>
                                                </div>
                                                <div class="empty" *ngIf="RequestPMSFlag">
                                                    <img src="./assets/images/check_circle.png" class="successbox">
                                                    <p class="empty_text">Great! <br>Our customer
                                                        representative
                                                        will reach out to you shortly</p>

                                                </div>
                                                <!-- empty view  -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bcl_schemes element">
                            <div class="_scheme_head">
                                <h4 class="heading">BajajCapital Suggest schemes</h4>
                                <button class="btn investbtn" (click)="WebenagaeBuyMF()" *ngIf="CTADisplayFlag">Invest Now</button>
                            </div>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="Equity-tab" data-toggle="tab"
                                        href="#Equity" role="tab" aria-controls="Equity"
                                        aria-selected="true">{{RecommSehemeType[0]}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="Debt-tab" data-toggle="tab" href="#Debt"
                                        role="tab" aria-controls="Debt" aria-selected="false">{{RecommSehemeType[1]}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="Liquid-tab" data-toggle="tab" href="#Liquid"
                                        role="tab" aria-controls="Liquid" aria-selected="false">{{RecommSehemeType[2]}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="Elss-tab" data-toggle="tab" href="#Elss"
                                        role="tab" aria-controls="Elss" aria-selected="false">{{RecommSehemeType[3]}}</a>
                                </li>
                                <span class="years">3 Years</span>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="Equity" role="tabpanel"
                                    aria-labelledby="Equity-tab">
                                    <div class="listing_wrapper">
                                        <ul class="listing" *ngFor="let item of SechemeType1">
                                            <li class="list">
                                                <img class="list_img" src='{{item.logo_image}}' alt='{{item.logo_img_alt_text}}'>
                                                <div class="list_name">
                                                    <p class="_s_name">{{item.scheme_name}}</p>
                                                    <p class="_s_category">{{item.scheme_description}}</p>
                                                </div>
                                                <div class="list_amount">
                                                    <p class="amount">{{item.scheme_return}}</p>
                                                    <span class="percentage" style="display:none;">{{item.scheme_return}} <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="10" viewBox="0 0 20 20" fill="none">
                                                            <path
                                                                d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                fill="#458704"></path>
                                                        </svg></span>
                                                </div>
                                            </li>                                            
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Debt" role="tabpanel"
                                    aria-labelledby="Debt-tab">
                                    <div class="listing_wrapper">
                                        <ul class="listing" *ngFor="let item of SechemeType2">
                                            <li class="list">
                                                <img class="list_img" src='{{item.logo_image}}' alt='{{item.logo_img_alt_text}}'>
                                                <div class="list_name">
                                                    <p class="_s_name">{{item.scheme_name}}</p>
                                                    <p class="_s_category">{{item.scheme_description}}</p>
                                                </div>
                                                <div class="list_amount">
                                                    <p class="amount">{{item.scheme_return}}</p>
                                                    <span class="percentage" style="display:none;">{{item.scheme_return}} <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="10" viewBox="0 0 20 20" fill="none">
                                                            <path
                                                                d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                fill="#458704"></path>
                                                        </svg></span>
                                                </div>
                                            </li>   
                                           
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Liquid" role="tabpanel"
                                    aria-labelledby="Liquid-tab">
                                    <div class="listing_wrapper">
                                        <ul class="listing" *ngFor="let item of SechemeType3">
                                            <li class="list">
                                                <img class="list_img" src='{{item.logo_image}}' alt='{{item.logo_img_alt_text}}'>
                                                <div class="list_name">
                                                    <p class="_s_name">{{item.scheme_name}}</p>
                                                    <p class="_s_category">{{item.scheme_description}}</p>
                                                </div>
                                                <div class="list_amount">
                                                    <p class="amount">{{item.scheme_return}}</p>
                                                    <span class="percentage" style="display:none;">{{item.scheme_return}} <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="10" viewBox="0 0 20 20" fill="none">
                                                            <path
                                                                d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                fill="#458704"></path>
                                                        </svg></span>
                                                </div>
                                            </li>   
                                           
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Elss" role="tabpanel"
                                    aria-labelledby="Elss-tab">
                                    <div class="listing_wrapper">
                                        <ul class="listing" *ngFor="let item of SechemeType4">
                                            <li class="list">
                                                <img class="list_img" src='{{item.logo_image}}' alt='{{item.logo_img_alt_text}}'>
                                                <div class="list_name">
                                                    <p class="_s_name">{{item.scheme_name}}</p>
                                                    <p class="_s_category">{{item.scheme_description}}</p>
                                                </div>
                                                <div class="list_amount">
                                                    <p class="amount">{{item.scheme_return}}</p>
                                                    <span class="percentage" style="display:none;">{{item.scheme_return}} <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="10"
                                                            height="10" viewBox="0 0 20 20" fill="none">
                                                            <path
                                                                d="M5.71515 14C4.96634 14 4.44661 13.6446 4.15596 12.9339C3.8642 12.2243 3.98179 11.5942 4.50873 11.0435L8.83519 6.52174C9.00159 6.34783 9.18186 6.21739 9.37599 6.13044C9.57013 6.04348 9.77813 6 10 6C10.2219 6 10.4299 6.04348 10.624 6.13044C10.8181 6.21739 10.9984 6.34783 11.1648 6.52174L15.4913 11.0435C16.0182 11.5942 16.1358 12.2243 15.844 12.9339C15.5534 13.6446 15.0337 14 14.2849 14H5.71515Z"
                                                                fill="#458704"></path>
                                                        </svg></span>
                                                </div>
                                            </li>   
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="buynowbtn">
                                <button class="btn" (click)="BuyMF()" *ngIf="CTADisplayFlag">Invest Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="insurance" role="tabpanel" aria-labelledby="insurance-tab">
                    <div class="left_wrapper">
                        <div class="welcome_card insurance">
                            <span class="beta">BETA</span>
                            <p class="text">Welcome,</p>
                            <h2 class="username">{{ClientName}}</h2>
                            <p class="sub_text">{{welcomeNotes}}</p>
                        </div>
                        <div class="invest_card element mob-padd">
                            <h4 class="heading">Insurance Portfolio</h4>
                            <app-insurance [inputUserType]="userType"></app-insurance>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right_bar">
                <div class="right_bar_wrapper">
                    <!-- <div class="balance element">
                        <div class="banks">
                            <div class="content">
                                <h6 class="heading">Check your Balance</h6>
                                <span class="sub_heading">Add your bank account</span>
                            </div>
                            <div class="logos">
                                <img src="./assets/images/Banks.png" class="img-fluid w-100" alt="banks">

                            </div>
                        </div>
                        <div class="add">
                            <button class="btn">Add Bank</button>
                        </div>
                    </div> -->

                    <div class="banners desktop" >
                        <div class="owl-two owl-carousel owl-theme" *ngFor="let item of BannerData" >
                            <div class="item">
                                <div class="item_data">
                                    <a class="banner_url" href='{{"https://"+item.banner_hyperlink}}' target="_blank">
                                        <!-- <span class="download">Download Now</span> -->
                                        <img [src]="item.banner_image" class="img-fluid w-100" [alt]="item.banner_img_alt_text">
                                    </a>
                                </div>
                            </div>      
                                         

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="marketing" class="tabcontent">
        <div class="calculator_wrapper">
            <div class="row">

                <div class="col-lg-3 col-xl-3 col-md-6 col-6" *ngFor="let item of CalculatorDetails">
                    <a href="{{'https://www.bajajcapital.com/financial-calculators/' + item.Calc_link}}" target="_blank">
                        <div class="icon_box">
                            <img [src]="item.image" class="img-fluid" [alt]="item.img_alt_text">
                            <h4 class="heading">{{item.Calc_title}}</h4>
                            <p class="text">{{item.Calc_description}}</p>
                        </div>
                    </a>
                </div>              
            </div>
        </div>
    </div>

    <div id="platform" class="tabcontent">
        <div class="help_wrapper">
            <div class="row">
                <div class="col-md-6 padd_left">
                    <div class="_left element">
                        <div class="content">
                            <span class="concern">Have any concerns?</span>
                            <p class="help">We are here to help you</p>
                        </div>
                        <div class="raise">
                            <a href="mailto:contactus@bajajcapital.com" (click)="copy('contactus@bajajcapital.com')"  class="link" *ngIf="CTADisplayFlag">Raise a Request</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 padd_right">
                    <div class="_right element">
                        <div class="_rms">
                            <p class="heading">My Relationship Manager</p>

                        </div>

                        <div class="_rms_details">
                            <div class="details">
                                <span class="material-icons-outlined icon">
                                    person
                                </span>
                                <div class="rm_details">
                                    <span class="name">{{RMName}}</span>
                                    <span class="branch">Branch: <strong>{{RMBranch}}</strong></span>
                                </div>
                            </div>
                            <!-- <div class="contact">
                                <ul class="contact_list">
                                    
                                    <li class="list">
                                        <div class="icons">
                                            <a href="{{'mailto:' + RMEmailId}}" (click)="copy(RMEmailId)"  >
                                                <img src="./assets/images/icons/Email.png" width="36" alt="email" >
                                            </a>
                                            <a href="{{'tel:' + RMMobile}}" (click)="copy(RMMobile)">
                                                <img src="./assets/images/icons/Call.png" width="36" alt="email" >
                                            </a>
                                            <a href="{{'https://wa.me/' + RMMobile}}" target="_blank" (click)="copy(RMMobile)">
                                                <img src="./assets/images/icons/WA.png" width="36" alt="email" >
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- updated htmls -->
                        <div class="_rms_data">
                            <ul class="_rm_list">
                                <li><span class="field">RM Email:</span>
                                    <p class="_name">{{RMEmailId}} <button class="btn"
                                            id="copyemailid" (click)="copy(RMEmailId)" *ngIf="CTADisplayFlag"><span class="material-symbols-outlined">
                                                content_copy
                                            </span></button><button class="btn" id="copied_txt"
                                            style="display: none;"><span class="material-symbols-outlined">
                                                check_circle
                                            </span></button></p>
                                </li>
                                <li><span class="field">RM Mobile Number:</span>
                                    <p class="_name">{{RMMobile}} <button class="btn" id="copynumber" (click)="copy1(RMMobile,'copynumber','phoneidicon')" *ngIf="CTADisplayFlag"><span
                                                class="material-symbols-outlined">
                                                content_copy
                                            </span></button>
                                        <button class="btn" id="phoneidicon" style="display: none;"><span
                                                class="material-symbols-outlined">
                                                check_circle
                                            </span></button>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <!-- updated htmls -->
                    </div>
                </div>
            </div>
        </div>

    </div>

     <!-- updated htmls -->
     <div class="footer_disclaimer">
        <p class="text">We’ll be adding Bonds, Fixed Deposits, and Direct Equity to the dashboard soon. For
            any concerns, reach out at <a class="link"
                href="mailto:mfconnect@bajajcapital.com">mfconnect@bajajcapital.com</a></p>
    </div>
    <!-- updated htmls -->
</div>