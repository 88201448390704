import { Component, OnInit } from '@angular/core';
import { apiCallingServices } from '../services/apiCallingServices';
import { SharingAdminProviderService } from '../services/SharingAdminProviderService';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, delayWhen } from 'rxjs';
import { ParseFlags } from '@angular/compiler';

declare function webengagePortfolioType(Typename:string):any;
declare function  webengageProductCardtype(Typename:string):any;
declare function   webengageInterestedBuytype(Typename:string):any;
declare function   webengageBuySuggest():any;


declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  EncodedString:any;
  parameter:any;
  newarr:any;
  userType:string="Client";
  CTADisplayFlag:boolean=true;

  constructor(private services:apiCallingServices,private _sharingservice: SharingAdminProviderService,private route:ActivatedRoute, public _router: Router) 
    { 
  //    console.log('Constructor-dashboard');
  //     this.parameter="";
  //   this.route.queryParams.subscribe(params => {   
  //     this.EncodedString=  params['Prm'];
  //    this.parameter=atob(params['Prm']);
  //   this.newarr = this.parameter.split(","); 
  //    console.log(this.newarr[0]);
  // })
  debugger;
  this.parameter="";
  this.route.queryParams.subscribe(params => {   
     this.EncodedString=  params['Prm'];
     if(this.EncodedString !=undefined && this.EncodedString!=""){
    this.parameter=atob(params['Prm']);
  //this.EncodedString=  params;
  // this.parameter=atob(this.EncodedString);
      this.newarr = this.parameter.split(",");               
     // console.log(this.newarr[0]);
      this.GetValueFromParam();
      // if(this.newarr.length>=2 && this.newarr[2]=='RM')
      //   this.DisapleCTA(false);

      this.workspacecss="workspace hideleftrigth"
   }
   });

 }
  mfdashBoardData:any={};
  pmfdashBoardData:any={};
  aifdashBoardData:any={};
  clientBasicData:any={};
  summrizedData:any[]=[];
  summrizedTotalData:any=[];
  summrizedMFData:any=[];
  summrizedNPSData:any=[];
  summrizedAIFData:any=[];
  summrizedPMSData:any=[];
  
  // LIData:any=[];
  // HealthData:any=[];
  // MotorData:any=[];
  // BikeData:any=[];

  mobileNo:any;
  ClientName:string='';
  totalMFflag:boolean=false;

  EmptyMfFlag:boolean=false;
  EmptyNPSFlag:boolean=false;
   EmptyAIFFlag:boolean=false;
   EmptyPMSFlag:boolean=false;
   RequestAIFFlag:boolean=false;
   RequestPMSFlag:boolean=false;
   PMSRequestFlag:boolean=false;
   EmptyLIlag:boolean=false;
   RequestLIFlag:boolean=false;

   NPSToken:string="";
   PRAN:string="";
   PAN:string="";
   DOB:string="";

   workspacecss:string="workspace";

   RMName:string='';
   RMMobile:string=''
   RMBranch:string='';
   RMEmailId:string='';

   NPSInvAmount:number=0;
   NPSCMV:number=0;
   NPSGainPercentage:number=0;
   NPSXIRR:number=0;

   CalculatorDetails:any=[];

   CRMToken:string='';
   TotalCMV:number=0;
   TotalINVAmount:number=0;

   RecommendedSchemes:any=[];
   BannerData:any=[];
   welcomeNotes:string="";

   RecommSehemeType:any=[];
   SechemeType1:any=[];
   SechemeType2:any=[];
   SechemeType3:any=[];
   SechemeType4:any=[];

  
  ngOnInit(): void {
   // console.log('ngOnInit-dashboard');
   // debugger;
    // if(this._router.routerState.snapshot.url.includes("dashboard"))
    //   delay(10000);
      
    if (sessionStorage.getItem("user") != null) {  
      this.mobileNo=sessionStorage.getItem("user");
    }
    else{
      if(!this._router.routerState.snapshot.url.includes("dashboard"))
      this._router.navigate(['login']);  
    }
    
    // if (localStorage.getItem("userdetail") != null) {  
    //  // this.mobileNo=localStorage.getItem("user");
     this.GetClientBasicInfoData();
    // }
    // else{
    //   this._router.navigate(['login']);  
    // }
    // else if(this.newarr[0]!="")
    //   {
    //     this.mobileNo=this.newarr[0];
    //     this.services.validateToken(this.mobileNo,this.newarr[1]).subscribe(data => {
    //        console.log(data);
    //     }, err => {
    //       console.log(err);
    //     });
    //   }
   
   // this.mobileNo="9971755945";
    //this.GetMFDashBoardData();
    
    this.GetProductSummrizedData();   
    this.GetNPSData();   
    //this.GetCRMToken();
    this.GetCMSData();
    this.GetRMDetails();
    this. getcalculatorData();

    if(this.newarr!=undefined && this.newarr.length>=2 && this.newarr[2]=='RM'){
      this.CTADisplayFlag=false;
    //  this.DisapleCTA(false);
      this.userType='RM';
    }
    

  }

  GetClientBasicInfoData()
  {
    // this.services.getClientBasicInfoData(this.mobileNo).subscribe(data => {
    //   debugger;
    //     this.clientBasicData=data;
    //     this.ClientName=this.clientBasicData[0]["client_name"];
    //     console.log(this.clientBasicData);
    // }, err => {
    //   console.log(err);
    // })
    debugger;
    this.clientBasicData=this._sharingservice.getUserDetails();
    this.ClientName=this.clientBasicData.ClientName;
  
  }

  GetProductSummrizedData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getSummerizeDashBoardData(this.mobileNo).subscribe(data => {
     
        this.summrizedData=data;
        if(data==0)
          {
            this.EmptyMfFlag=true;
            this.EmptyAIFFlag=true;
            this.EmptyPMSFlag=true;

            this.totalMFflag=false;
          }
          else{
        this.summrizedMFData=this.summrizedData.filter(e => e.PRODUCT=='MF');
       // this.summrizedNPSData=this.summrizedData.filter(e => e.PRODUCT=='NPS');
        this.summrizedAIFData=this.summrizedData.filter(e => e.PRODUCT=='AIF');
        this.summrizedPMSData=this.summrizedData.filter(e => e.PRODUCT=='PMS');
        this.summrizedTotalData=this.summrizedData.filter(e => e.PRODUCT=='TOTAL');
       //debugger;
        if(this.summrizedMFData.length==0)
          this.EmptyMfFlag=true;       
        if(this.summrizedAIFData.length==0)
          this.EmptyAIFFlag=true;
        if(this.summrizedPMSData.length==0)
          this.EmptyPMSFlag=true;
        
      if(this.summrizedMFData.length>0 || this.summrizedAIFData.length>0 || this.summrizedPMSData.length)
          this.totalMFflag=true;
    }
       // console.log(this.summrizedData);
    }, err => {
      console.log(err);
    })
   
  }

  GetMFDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getMfDashBoardData(this.mobileNo).subscribe(data => {
        this.mfdashBoardData=data;
    }, err => {
      console.log(err);
    })
   // console.log(this.mfdashBoardData);
  }

  GetPMSDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getPMEDashBoardData(this.mobileNo).subscribe(data => {
      //console.log('data', data)
      this.mfdashBoardData=data;
    }, err => {
      console.log(err);
    })
    //console.log(this.mfdashBoardData);
  }

  GetAIFDashBoardData()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getAIFDashBoardData(this.mobileNo).subscribe(data => {
   //   console.log('data', data)
      this.mfdashBoardData=data;
    }, err => {
      console.log(err);
    });
    
  }


 

  GetNPSData()
  {
    if(this.mobileNo==undefined)
      return;
      if(this.NPSToken=="")
        {
          this.services.getNPSToken().subscribe(data => {
            let datavalue:any=data;
              if(datavalue.success==true){
                this.NPSToken=datavalue.token
                this.services.GetNPSData(this.mobileNo,this.NPSToken)?.subscribe(data1=>{
          //  this.services.GetNPSData('9545055955',this.NPSToken)?.subscribe(data1=>{
             // debugger;
                 // console.log('data', data1)
                  let datavalue1:any=data1;
                  if(datavalue1.success==true){
                  this.summrizedNPSData=datavalue1.data;
                  this.PRAN=this.summrizedNPSData.PRAN;
                  this.PAN=this.summrizedNPSData.PAN;
                  this.DOB=this.summrizedNPSData.DOB;
                  this.NPSInvAmount=Number(this.summrizedNPSData.Amount_Invested);
                  this.NPSCMV=Number(this.summrizedNPSData.Current_Amount);
                  this.NPSGainPercentage=this.summrizedNPSData.Gain_Loss;
                  this.NPSXIRR=this.summrizedNPSData["Gain_Loss(%)"];
                //  console.log(this.summrizedNPSData);                 
                  if(this.NPSInvAmount>0 )
                    this.totalMFflag=true;
                  //this.TotalCMV=this.TotalCMV+
                }
                else{
                  this.EmptyNPSFlag=true;
                }
                }, err => {
                  console.log(err);
                });
              }
              else
              { 
                  this.summrizedNPSData=[];               
              }
              
          });
        }
        else{
          this.services.GetNPSData(this.mobileNo,this.NPSToken)?.subscribe(data=>{
           // console.log('data', data)
            this.summrizedNPSData=data;
            this.PRAN="";
          }, err => {
            console.log(err);
          });

        }
          
   // console.log(this.mfdashBoardData);
  }

  AIFInterested()
  {
    this.RequestAIFFlag=true;
    this.sendRequestToCRM('bcl_dashboard_investments','AIF');
  }
  PMSInterested(zeroflag:boolean)
  {
    if(zeroflag==true){
    this.RequestPMSFlag=true;
    }
  else{
    this.PMSRequestFlag=true;
  }
    this.sendRequestToCRM('bcl_dashboard_investments','PMS');
    
     //this.GetCMSData();
  }

  BuyMF()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getMFProductToken(this.mobileNo).subscribe(data => {
      debugger;
    let datavalue:any=data;
    if(datavalue.error!=undefined && datavalue.error.message=='No User Found')
      {
       // window.open('https://uatmf.bajajcapital.com/login','_blank')
        //window.open('https://uatmf.bajajcapital.com/signup','_blank');
        window.open(this.services.baseUrl["WealthSignUpUrl"],'_blank');
      }
      else{      
      let token:string=datavalue["authentication"];
      let buynowurl:string=this.services.getMFBuyNowUrl(token);
      window.open(buynowurl, '_blank');
      //this._router.navigate([buynowurl])   
      }
      
    }, err => {
      console.log(err);
      //window.open('https://uatmf.bajajcapital.com/signup','_blank')
      window.open(this.services.baseUrl["WealthSignUpUrl"],'_blank');
    });
    
    webengageInterestedBuytype('Buy Now');
    
  }
  WebenagaeBuyMF()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.getMFProductToken(this.mobileNo).subscribe(data => {
      debugger;
    let datavalue:any=data;
    if(datavalue.error!=undefined && datavalue.error.message=='No User Found')
      {
       // window.open('https://uatmf.bajajcapital.com/login','_blank')
        //window.open('https://uatmf.bajajcapital.com/signup','_blank');
        window.open(this.services.baseUrl["WealthSignUpUrl"],'_blank');
      }
      else{      
      let token:string=datavalue["authentication"];
      let buynowurl:string=this.services.getMFBuyNowUrl(token);
      window.open(buynowurl, '_blank');
      //this._router.navigate([buynowurl])   
      }
      
    }, err => {
      console.log(err);
      //window.open('https://uatmf.bajajcapital.com/signup','_blank')
      window.open(this.services.baseUrl["WealthSignUpUrl"],'_blank');
    });
    
    webengageBuySuggest();
  }

  startNPS()
  {
 
    if(this.PRAN=='' && this.PAN=='' && this.DOB==''){
      window.open('https://www.bajajcapital.com/nps/', '_blank');
      return;
    }
    
    this.services.getNPSToken().subscribe(data => {
      let datavalue:any=data;
        if(datavalue.success==true){
          this.NPSToken=datavalue.token
    //      this.services.GetNPSData(this.mobileNo,this.NPSToken)?.subscribe(data1=>{
      this.services.StartNPSUrl(this.PRAN,this.PAN,this.DOB,this.NPSToken)?.subscribe(data1=>{
         //   console.log('data', data1)
            let datavalue1:any=data1;
            let npsurl=datavalue1.url;
            window.open(npsurl, '_blank');
          }, err => {
            console.log(err);
          });
        }
        else
        { 
            this.summrizedNPSData=[];
         
        }
        
    });

    webengageBuySuggest();
   
  }



  GetValueFromParam()
  {
    if(this.newarr !=undefined && this.newarr[0]!="")
      {
        this.mobileNo=this.newarr[0];
        sessionStorage.setItem("user", this.mobileNo);     
        
       this.services.validateToken(this.mobileNo,this.newarr[1]).subscribe(data => {
          this.services.getClientBasicInfoData(this.mobileNo).subscribe(
          data => {
            let data1:any=data;
            this.clientBasicData.ClientName = data1[0]["client_name"];    
            this.clientBasicData.Clientcode = data1[0]["client_code"];
            this.clientBasicData.PAN = data1[0]["Pan"];
            this.clientBasicData.Email = data1[0]["Email"];
            this.clientBasicData.Mobile = data1[0]["mobile"];
          //  console.log(JSON.stringify(data1));
          //  console.log('get login data:-'+ this.clientBasicData);
            if (this.clientBasicData !=null && this.clientBasicData.Mobile !='') { 
              sessionStorage.setItem("userdetail", JSON.stringify(this.clientBasicData));
              sessionStorage.setItem("user", this.mobileNo);     
                  
              this._sharingservice.settUserDetails(this.clientBasicData); 
              this.clientBasicData=this._sharingservice.getUserDetails();
             // this.ClientName=this.clientBasicData.ClientName;
             this.ClientName=this._sharingservice.getClientName();  
          //   this._router.navigate([this._router.routerState.snapshot.url]);        

             //this._router._routerState.snapshot.url
                  
              return true;
            }
            //this.errorMsg = 'Failed to login';
            //  alert('your login/password is wrong !');
              
            return false;       
          },
          error => {
            console.log("Features: Error in Exceution " + error.message);
           // this.errorMsg = 'Failed to login';
           
          }
        );


         
        }, err => {
          console.log(err);
        });
      }
     
  }


  GetRMDetails()
  {
    if(this.mobileNo==undefined)
      return;
    this.services.GetRMDetails(this.mobileNo).subscribe(data => {
     // debugger;
      let datavalue:any=data;
      this.RMName=datavalue[0]["RM_NAME"]; 
      this.RMEmailId=datavalue[0]["EMAIL"]; 
      this.RMBranch=datavalue[0]["BRANCH_NAME"]; 
      this.RMMobile=datavalue[0]["MOBILE"]; 
      //this._router.navigate([buynowurl])   
      
    }, err => {
      console.log(err);
    });
  }

  getcalculatorData()
  {
    this.services.getCalculatorData().subscribe(data => {
     // debugger;
      this.CalculatorDetails=data.rows[0].calculator_section;      
      
    }, err => {
      console.log(err);
    });
  }


  

  StartJourney()
  {
    window.open('https://wealth.bajajcapital.com/signup ', '_blank');
  }


 
  sendRequestToCRM(campaign_name:string,product:string)
  {
    // debugger;
    // this.services.getCRMAPIToken().subscribe(data => {
    //   debugger;
    //   let datavalue=data;
    //   let tokenvalue=data.data.access_token;      
    //   this.CRMToken=tokenvalue;
      
    //  this.createLeadtoCRM(campaign_name,product,tokenvalue);
    // }, err => {
    //   console.log(err);
    // });

    this._sharingservice.createLeadtoCRMUsingBCL(campaign_name,product);
    if(product=='Life Insurance')
    this.RequestLIFlag=true;
  } 

  copy1(text:string,ele1:string,ele2:string){
    debugger;
    this._sharingservice.copy(text);
    let elment1=document.getElementById(ele1);
    let elment2=document.getElementById(ele2);
    $('#'+elment1).hide();
    $('#'+elment2).show()
    //reverse()
    setTimeout(() => {
      $('#'+elment1).show();
      $('#'+elment2).hide()
  }, 2500)
    }

    copy(text:string){
      this._sharingservice.copy(text);
     
      }


      DisapleCTA(flage:boolean)
      {
        var buttons = document.getElementsByTagName('button');
        for (let i = 0; i < buttons.length; i++) {
            let button = buttons[i];
           // let type = button.getAttribute('type') || 'submit'; // Submit is the default
           button.style.visibility = 'hidden';
            // ...
        }
        
      }

      GetCMSData()
      {
        debugger;
        this.services.getRecommendedScheme().subscribe(data => {
           debugger;
           this.RecommendedSchemes=data.rows[0].schemes_section;      
           this.BannerData=data.rows[0].banner_section;   
           this.welcomeNotes=data.rows[0].welcome_card_subheading;
           this.UniqueschemeType();
           //this.SechemeType1=this.RecommendedSchemes.filter
         }, err => {
           console.log(err);
         });
      }

      
      UniqueschemeType()
      {   
        this.RecommSehemeType=[];  
        this.SechemeType1=[];   
        this.SechemeType2=[];  
        this.SechemeType3=[];  
        this.SechemeType4=[];   
        for (var i = 0; i < this.RecommendedSchemes.length; i++) 
        {
            let schemeType=this.RecommendedSchemes[i].scheme_type[0];
            if(this.RecommSehemeType.indexOf(schemeType)==-1)
              this.RecommSehemeType.push(schemeType);
        }
        this.RecommSehemeType.sort();
        for (var i = 0; i < this.RecommendedSchemes.length; i++) 
          {
              let schemeType=this.RecommendedSchemes[i].scheme_type[0];
              if(schemeType==this.RecommSehemeType[0])
                this.SechemeType1.push(this.RecommendedSchemes[i])
              if(schemeType==this.RecommSehemeType[1])
                this.SechemeType2.push(this.RecommendedSchemes[i])
              if(schemeType==this.RecommSehemeType[2])
                this.SechemeType3.push(this.RecommendedSchemes[i])
              if(schemeType==this.RecommSehemeType[3])
                this.SechemeType4.push(this.RecommendedSchemes[i])
          }

          setTimeout(() =>{
            $('.owl-two').owlCarousel({
                   loop: true,
                   items: 1,
                   margin: 16,
                   dots: true,
                   autoplay: true,
                   autoplayTimeout: 3500,
                   autoplayHoverPause: true
               });
           },800)
        
      }

  webengagePortfolio(Investmenttype:string)
  {
    webengagePortfolioType(Investmenttype);
    
  } 
  webengageProductCard(cardtype:string)
  {
    webengageProductCardtype(cardtype);
    
  } 
 

      
}
