import { Injectable } from '@angular/core';
import { ClientDetails } from '../models/ClientDetails';
import { apiCallingServices } from './apiCallingServices';

@Injectable()
export class SharingAdminProviderService {

    public loginUserDetails:ClientDetails=new ClientDetails();
    constructor(private services:apiCallingServices) { console.log('Initialized Sharing Provider Service');}

    settUserDetails(data: ClientDetails) {
        if(data==null){
            this.loginUserDetails=new ClientDetails();
        }
        else
        this.loginUserDetails = data;

    }
    getUserDetails() {
        debugger;
       if(sessionStorage.getItem("userdetail")!=null){      
      let userjson=JSON.parse(sessionStorage.getItem('userdetail') || '{}');
      this.loginUserDetails = userjson !== null ? userjson : new ClientDetails();
      
        return this.loginUserDetails;
         }
        else{
            return this.loginUserDetails;
        }
    }

    getClientName() {
        return this.loginUserDetails.ClientName;
    }
    getMobile()
    {
        let mobile:any;
        if (sessionStorage.getItem("user") != null) {  
            mobile=sessionStorage.getItem("user");
          }
          return mobile;

    }









    copy(text:string){
        debugger;
        var inp =document.createElement('input');
        document.body.appendChild(inp)
        inp.value =text;
        inp.select();
        document.execCommand('copy',false);
        inp.remove();
        }
    
       
      
      
      
      
          createLeadtoCRM(campaign_name:string,product:string,crmtoken:string)
          {
            debugger;
            let paramsvalue={
              "first_name": this.loginUserDetails.ClientName,
              "last_name": "",
              "mobile_no": this.loginUserDetails.Mobile,
              "email": this.loginUserDetails.Email,
              "company": "BCL",
              "city": "",
              "state" : "",
              "postal_code" : "",
              "utm_source":"",
              "utm_medium":"",
              "utm_campaign":"",
              "assigned_cso":"",
              "campaign_name": campaign_name,
              "landing_page_url": "",
              "go_to_cso": 1,
              "rm_name": "",
              "description": "",
              "lead_source": "Website",
              "dob_c": "",
              "age_in_number_c": 0,
              "service_type_c": "",
              "rm_payroll_id": "",
              "lead_type_c": "",
              "product": product,
              "retirement_date": "",
              "webinar_id": "",
              "download_edge_report_verified": "No",
              "request_callback": "No",
              "clicked_on_start_investing": "No",
              "email_verified": "No",
              "otp_verified": "No",
              "episode_no_c": "2",
              "season_no_c": "24",
              "drop_stage": "data",
              "remarks": "",
              "journey_continuation_link": ""
              };
              
              //let paramvalue:any={};
      
                
                  this.services.CreateLeadINCRM(paramsvalue,crmtoken).subscribe(data => {
                    debugger;
                    let datavalue=data;
                   // let tokenvalue=data.data.access_token;      
                   // this.CRMToken=tokenvalue;
                  }, err => {
                    console.log(err);
                  });
      
          }
      
      
          createLeadtoCRMUsingBCL(campaign_name:string,product:string)
          {
            debugger;
            let paramsvalue={
              "Name": this.loginUserDetails.ClientName,
              "Mobile":this.loginUserDetails.Mobile,
              "Company": "BCL",
              "Email": this.loginUserDetails.Email,
              "Campaign_Name": campaign_name,
              "Landing_Page_URL": "http://localhost:4200/retirement-planning",
              "LeadSource": "",
              "Pincode": "",
              "Product": product,
              "RMName": "",
              "Utm_Campaign": campaign_name,
              "Utm_Medium": "",
              "Utm_Source": ""
              };
              
              //let paramvalue:any={};
      
                
                  this.services.CreateLeadINCRMusingBCLAPI(paramsvalue).subscribe(data => {
                    debugger;
                    let datavalue=data;
                   // let tokenvalue=data.data.access_token;      
                    //this.CRMToken=tokenvalue;
                  //  console.log(datavalue);
                  }, err => {
                    console.log(err);
                  });
      
          }
        
}